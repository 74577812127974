/* 1038 */

.viewContentPopUp{
    position: absolute;
    background: white;
    height: 60%;
    width: 40%;
    margin: auto;
    padding: 24px;
    /* overflow-y: scroll; */
    border-radius: 12px;
    overflow-y: auto;
    scrollbar-color: #bdbdbd transparent;
}

.viewContentPopUp::-webkit-scrollbar{
    width: "8px",
}

/* tablate */
@media (max-width:1038px) {
    .viewContentPopUp{
        width: 70% !important;
    }
  }
  

  /* mobile */
  @media (max-width:591px) {
    .viewContentPopUp{
        width: 81% !important;
    }
  }


  @media (max-width:506px) {
    .hide-mob-element {
        display: none !important;
    }
  }


  @media (max-width:414px) {
        .show-mob-element {
            display: block !important;
        }
  }

