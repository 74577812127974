/* Sidebar Styles */


.sidebar_wrapper {
    width: 19.375rem;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: left 0.3s ease-in-out;
}

.sidebar{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    transition: left 0.3s ease-in-out;
    z-index: 10000;
    box-sizing: border-box;
    overflow-y: scroll;
}

.logo {
    width: auto;
    height: 5.625rem;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    display: flex;
    align-items: center ;
    justify-content: space-between;
}

.menu-logo{
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-right: 24px;
}
.img-logo{
    width: 166px;
    height: 90px;
}

.navigation {
    display: flex;
    flex-flow: column;
    flex-shrink: 1;
    margin: 3rem 1.5rem 0 1.5rem;
}

.navigation ul li a {
    display: flex;
    flex-flow: 'row';
    text-decoration: none;
    color: var(--grey, #8D97A2);
    transition: background-color 0.2s;
}

.list-padding {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.list-img {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    /* background: url(/src/packages/blocks/navigationmenu/assets/user-profile.png); */
}

.list-text {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 1.3rem */
    /* margin-left: 1rem */
}

.list-text-selected {
    color: #ffffff;
    font-weight: bold;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: 130%;
    margin-left: 1rem
}
.MenuItem{
    height: 3.25rem;
    cursor: pointer;
    position: relative;
}
.MenuItem .list-item {
    width: 97%;
}
.selectedMenu .active_border{
    display: block;
}
.active_border{
    width: 4px;
    height: 100%;
    border-radius: 12px;
    background-color: #BFCC33;
    display: none;
    position: absolute;
    right: -4px;
    top: -1px;
}

.selectedMenu .list-item {
    background-color: #32569A;
    border-radius: 0.75rem;
    color: #ffffff;
    font-weight: bold;
}

.navigation .list-text{
    color:  #8D97A2;
    font-weight: 400;
}
.navigation .logout-text {
    color:  #DC2626 !important;
    font-weight: 400 !important;
    font-family: "Barlow Semi Condensed", sans-serif !important;
}

.selectedMenu .list-text{
    font-weight: 700;
    color: #FFFFFF;
    
}

.selectedMenu div img {
    filter: brightness(0) invert(1);
  }


.sign_out_popup_wrapper {
    width: 100vw;
    height: 100vh;
    background: #1C304540;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign_out_popup_container {
    width: 600px;
    height: 180px;
    border-radius: 8px 8px 32px 8px;
    background: #FFFFFF;
    position: relative;

}

.sign_out_text_message {
    margin: 40px 0px 0px 40px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color:  #0F172A;

}

.sign_out_button_container {
    margin-top: 24px;
    width: 600px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #E2E8F0;
}

.sign_out_cancel_button {
    width: 105px;
    height: 44px;
    padding: 10px, 16px, 10px, 16px;
    border-radius: 8px;
    margin-right: 24px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #32569A;
    color: #32569A;
    cursor: pointer;
    background-color: #FFFFFF;
}

.sign_out_logout_button {
    width: 105px;
    height: 44px;
    padding: 10px, 16px, 10px, 16px;
    border-radius: 8px;
    border: 1px;
    margin-right: 24px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    background: #DC2626;
    color: #FFFFFF;
    cursor: pointer;
}

.sign_out_modal_close {
    position: absolute;
    right: 25px;
    top: 25px;
}

.sign_out_modal_close img {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.horigentle_nav_line{
    border: 1px solid #0000001A;
}

.language_container{
    width: 70px;
    display: none;
    margin-right: 21px;
} 



 /* tablet */
 @media (max-width:1279px) {
    .hide-tab-element {
        display: none;
    }

    .sidebar_wrapper{
        width: 100%;
        height: 100vh;
        background: #0000004F;
        position: fixed !important ;
    }

    .sidebar {
        padding-top: 98px !important;
        width: 32.5rem !important;
    }

    .logo {
        margin-left: 1.5rem !important;
    }

    .navigation {
        margin: 1.5rem 0 0 0 !important;
    }

    .selectedMenu .list-item {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 0.75rem !important;
        border-bottom-right-radius: 0.75rem !important;
    }


    .sign_out_popup_container
    {
        max-width: 400px;
        height : 150px;
    }

    .sign_out_button_container
    {
        max-width: 400px;
    }

    .sign_out_text_message
    {
        margin : 18px 0 0 18px
    }
 }


 /* mobile */
 @media (max-width:414px) {
    .show_mob_element{
        display: block !important;
    }

    .hide-mob-element {
        display: none !important;
    }
    .sidebar {
        padding-top: 50px !important;
        width: 16.6875rem !important;
    }

    .logo{
         height: 50px;
    }

    .menu-logo{
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    .img-logo{
        width: 92.22px;
        height: 50px;
    }

    .MenuItem .list-item{
        width: 96% !important;
    }

    .list-padding{
        margin-left: 23px !important;
    }

    .sign_out_popup_container
    {
        max-width: 300px;
        height : 150px;
    }

    .sign_out_button_container
    {
        max-width: 300px;
    }

    .sign_out_text_message
    {
        margin : 18px 0 0 18px
    }
 
 }
