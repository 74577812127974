.send-invite-outer-container {
  background-color: var(--l-grey);
  width: 100%;
}

.account-creation-outer-container{
  height: 100%;
  background-color: var(--l-grey);
  overflow-x: hidden;
}

.send-invite-container {
  display: flex;
  flex-direction: column;
  width: 76%;
  padding: 0px 2.06rem;
}

.send-invite-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.top-field-input-name {
  width: 47.5% !important; 
}

.header-prompt-container {
  width: 53.25%;
}

.header-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 68%;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 1.06rem;
}

.sendInvite-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 22rem;
  box-sizing: border-box;
  gap: 2rem;
  padding:  2rem 2rem 2rem;
}

.sendInvite-button-container button {
  /* margin: 2rem 2rem 2rem 0; */
}

.success-modal-container {
  display: flex;
  position: absolute;
  padding-top: 15rem;
  padding-left: 25rem;
  top: 0;
  left: 0;
  height: 120%;
  width: 100%;
  background-color: rgba(28, 48, 69, 0.25);
  z-index: 10000;
  flex-direction: column;
}

.success-modal-inner-container {
  height: 19.25rem;
  width: 40rem;
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-image {
  width: 6.6875rem;
  height: 6.375rem;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
}

.modal-text {
  color: #000;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.disable-scroll{
  overflow: hidden;
}


@media screen and (max-width: 1100px) {
  .success-modal-container{
    padding-left: 11rem;
   }
}

@media (max-width: 1200px) {
 .success-modal-container{
  padding-left: 15rem;
 }
}

@media (max-width: 850px) {
  .success-modal-container{
   padding-left: 7rem;
  }
 }

 @media (max-width: 500px) {
  .top-field-input-name{
    width: 100% !important;
  }
  .send-invite-top-container{
    flex-direction: column;
  }
 }

 