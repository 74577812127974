p {
  user-select: none;
}

.start-endornment-icon {
  background-color: transparent;
  margin-left: 0.75rem;
}

.admin-listing-input-container {
  overflow: hidden;
  justify-content: flex-start;
  padding-right: 1rem;
}

.admin-listing-input-field {
  width: 99%;
}

.input-container-admin-listing {
  width: 12.5rem;
}

.admin-listing-button-font {
  color: var(--neutral-gray-gray-500, #667085);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00438rem;
}

.admin-listing-img-input-icon {
  width: 1rem;
  height: 1rem;
}

.admin-listing-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.admin-listing-prompt {
  width: 40%;
}

.admin-listing-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-listing-register-button {
  background-color: #32569a;
  border: 0px solid;
  align-items: center;
}

.admin-listing-register-button-text {
  color: #fff !important;
}

.admin-listing-inner-container {
  width: 97%;
}

.admin-listing-sub-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.subheader-right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-listing-delete-all {
  background-color: #dc2626;
  border: 0px solid;
}

.admin-listing-delete-all-button {
  margin-right: 1.56rem;
  display: flex;
  min-width: 8rem;
  background-color: #dc2626;
  border: 0px solid;
  width: max-content;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.admin-listing-delete-btn-text {
  color: var(--basic-white, #fff);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}

.admin-listing-margin-right {
  margin-right: 1.56rem;
}

.admin-listing-margin-left {
  margin-left: 1.56rem;
}

.admin-listing-filter-container {
  width: 5.75rem;
}

.admin-listing-filter-button-text {
  color: #667085 !important;
}

.admin-listing-button {
  display: flex;
  min-width: 5rem;
  background-color: #fff;
  border: 1px solid #e0e2e7;
  width: max-content;
  padding: 0.62rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.admin-listing-filter-button {
  min-width: 5.75rem;
}

.admin-listing-sort-button-text {
  margin-left: 0.5rem;
}

.admin-listing-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.admin-listing-padding-right5 {
  padding-right: 0.5rem;
}

.admin-listing-table {
  width: 100%;
}

.admin-listing-table-container {
  /* margin-left: 2rem; */
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 94%;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
  justify-content: space-between;
  /* margin-bottom: 2rem; */
  margin: 0rem 2rem 2rem 2rem;
}

.admin-listing-table-inner-container {
  width: 100%;
  overflow-x: auto;
}

.admin-listing-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}

.admin-listing-table-header-top-container {
  display: flex;
  flex-direction: column;
}

.admin-listing-table-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3.75rem;
  border-bottom: 2px solid #f6f7fa;
  overflow: hidden;
}

.admin-listing-table-item-container {
  height: 5rem;
  display: flex;
}

.admin-listing-table-item-selected {
  background: var(--Gradient, #e1f0e6);
}

.admin-listing-table-field-name-container {
  display: flex;
  padding: 1.125rem 1.375rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  width: 22.6%;
  overflow: hidden;
}
.admin-listing-table-field-email-container {
  display: flex;
  padding: 1.125rem 1.375rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  width: 21.5%;
  overflow: hidden;
}
.admin-listing-table-field-mobile-container {
  display: flex;
  padding: 1.125rem 1.375rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  width: 15.3%;
}
.admin-listing-table-field-role-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  width: 15.9%;
  overflow: hidden;
}

.admin-listing-table-field-action-container {
  display: flex;
  padding: 1.125rem 1.375rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  width: 9.4%;
  
}

.admin-listing-checkbox-img {
  height: 1.25rem;
  width: 1.25rem;
  background-color: var(--Primary-Green, #bfcc33);
  border-radius: 0.375rem;
  cursor: pointer;
}

.admin-listing-name-text {
  color: var(--Blue, #1c3045);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.admin-listing-name-item-text {
  color: var(--Blue, #1c3045);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.admin-listing-email-item-text {
  color: var(--Blue-70, rgba(28, 48, 69, 0.7));
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.admin-listing-action-img {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}

.admin-listing-action-menu-container {
  position: relative;
  display: flex;
  width: 12rem;
  height: 5.125rem;
  padding: 0 0.25rem;
  flex-direction: column;
  gap: 0.125rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
  justify-content: center;
  margin-top: 8rem;
}

.admin-listing-action-menu-item {
  display: flex;
  height: 2.25rem;
  padding: 0 1.25rem 0 1rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
  cursor: pointer;
  color: var(--Primary-Blue, #32569a);

  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;

  &:hover {
    background-color: var(--Light-Green, #e1f0e6);
  }
}

.admin-listing-table-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 4.5rem;
  padding: 0 1.5rem;
  gap: 0.75rem;
  align-self: stretch;
  border-top: 2px solid #f6f7fa;
}

.admin-listing-table-footer-text {
  color: var(--neutral-gray-gray-500, #667085);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.00438rem;
}

.admin-listing-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-listing-page-icon {
  height: 1rem;
  width: 1rem;
}

.admin-listing-page-inner-container {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: rgba(141, 151, 162, 0.3);
  cursor: pointer;
  margin-left: 0.5rem;
}

.admin-listing-page-inner-container-selected {
  background: var(--Primary-Blue, #32569a);
}

.admin-listing-page-number-text {
  color: var(--Primary-Blue, #32569a);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00438rem;
  user-select: none;
}

.admin-listing-page-number-text-selected {
  color: var(--neutral-white, #fff);
}

.admin-listing-filter-menu {
  display: flex;
  flex-direction: column;
  width: 18.5625rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
  position: absolute;
  margin-left: -12.5rem;
  margin-top: 1rem;
  overflow-x: hidden;
}

.admin-listing-filter-menu-header {
  display: flex;
  height: 2rem;
  padding: 0.25rem 1.25rem 0.25rem 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
}

.admin-listing-modal-separator {
  display: flex;
  width: 97%;
  height: 0.0625rem;
  outline: none;
  align-self: center;
  align-items: center;
  border: 0px;
  background: rgba(141, 151, 162, 0.4);
  margin-bottom: 0.5rem;
}

.admin-listing-filter-menu-clear-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Grey, #8d97a2);
  padding: 0.75rem ;
  cursor: pointer;
  user-select: none;

  /* Barlow/14/Regular */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.admin-listing-filter-menu-header-text {
  color: var(--neutrals-cool-gray-900, #0f172a);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.admin-listing-filter-menu-header-img {
  width: 1.5rem;
  height: 1.5rem;
}

.admin-listing-filter-menu-item {
  display: flex;
  padding: 0.25rem 11rem 0.25rem 0.75rem;
  align-items: flex-end;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
}

.admin-listing-filter-menu-item-img {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--Primary-Green, #bfcc33);
  cursor: pointer;
}

.admin-listing-filter-menu-item-tick-img {
  background-color: #bfcc33;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--Primary-Green, #bfcc33);
}

.admin-listing-filter-menu-item-text {
  color: var(--neutrals-cool-gray-900, #0f172a);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.admin-listing-filter-menu-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.admin-listing-filter-menu-button {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Primary-Blue, #32569a);
  background-color: transparent;
  color: var(--Primary-Blue, #32569a);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 171.429% */
  cursor: pointer;
}

.admin-listing-filter-menu-button-blue {
  border-radius: 0.5rem;
  background: var(--Primary-Blue, #32569a);
  color: var(--basic-white, #fff);
}

.admin-listing-sort-container {
  display: flex;
  width: 5rem;
  justify-content: center;
  align-items: center;
}

.admin-listing-sort-menu {
  display: flex;
  flex-direction: column;
  width: 18.5625rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
  position: absolute;
  /* margin-left: 13.5rem; */
  margin-top: 20rem;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
}
.admin-listing-sort-menu-item {
  display: flex;
  height: 2.25rem;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
  padding: 0.38rem 0;
}
.admin-listing-sort-menu-item-img-div {
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid var(--Primary-Green, #bfcc33);
  border-radius: 2rem;
  stroke-width: 1px;
  stroke: var(--Primary-Green, #bfcc33);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem 0.75rem;
  cursor: pointer;
}

.admin-listing-sort-menu-item-text {
  color: var(--neutrals-cool-gray-900, #0f172a);

  /* Barlow/14/Regular */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  user-select: none;
  cursor: auto;
}
.admin-listing-sort-menu-item-tick-img {
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
}

.admin-listing-sort-menu-item-arrow {
  width: 1rem;
  height: 0.5rem;
  flex-shrink: 0;
}

.admin-listing-sort-menu-item-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.admin-listing-delete-modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(28, 48, 69, 0.25);
  z-index: 10000;
}

.admin-listing-delete-modal-inner-container {
  display: flex;
  /* padding-top: 40px; */
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem 0.5rem 2rem 0.5rem;
  background: #fff;
  width: 37.5rem;
  padding-left: 1rem;

  /* shadow-lg */
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09);
}

.admin-listing-close-modal-img {
  width: 1.5rem;
  height: 1.5rem;
  align-self: flex-end;
  margin: 1rem 1rem 0 0;
}

.admin-listing-delete-modal-header-text {
  /* align-self: stretch; */
  width: 100%;
  color: var(--neutrals-cool-gray-900, #0f172a);

  /* Barlow/16/Semi bold */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  margin-bottom: 1rem;
  height: 1.5rem;
}

.alternate-searchbar {
  display: none;
  width: 93%;
  box-shadow: 10px 10px 300px -31px rgba(0,0,0,1);
}

.cursor-pointer {
  cursor: pointer;
}

.admin-listing-delete-modal-subheader-text {
  /* align-self: stretch; */
  width: 100%;
  height: 1.5rem;
  color: var(--neutrals-cool-gray-900, #0f172a);

  /* Barlow/16/Regular */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  margin-bottom: 1.5rem;
}

.admin-listing-delete-modal-separator {
  width: 100%;
}

.admin-listing-delete-buttons-container {
  align-self: flex-end;
  margin: 1.5rem 1.5rem 1.5rem 0rem;
}

.admin-listing-delete-button-text {
  color: var(--basic-white, #fff);

  /* Barlow/16/Bold */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}

.admin-listing-modal-buttons {
  margin-right: 1.5rem;
}

.admin-listing-delete-button {
  border-radius: 0.5rem;
  background: var(--status-red-600, #dc2626);
  border: 0px;
  min-width: 6.56rem;
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  cursor: pointer;
}
.admin-listing-delete-cancel-button {
  color: var(--Primary-Blue, #32569a);
  background-color: transparent;
  border: 1px solid var(--Primary-Blue, #32569a);
}

.margin-zero {
  margin-top: 0;
}

/* .admin-staff-listing-input-container{

} */

/* pc */
@media (max-width: 1280px) {
  .contactus-right-container {
    background-color: white;
  }

  .contactus-outer-container {
    background-color: white;
    padding-left: 1.5rem;
  }

  .admin-listing-table {
    width: 100%;
    overflow-x: auto;
  }

  .admin-listing-table-container {
    overflow-x: auto;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 414px) {
  .contactus-right-container {
    background-color: white;
  }

  .admin-listing-button {
    border: 0px;
    min-width: 0;
    padding: 0;
  }

  .admin-staff-listing-input-container {
    border: 0px;
  }

  .admin-listing-search-input {
    display: none;
  }

  .admin-listing-search-start-endornment {
    margin-left: 0;
  }

  .admin-listing-delete-btn-text {
    display: none;
  }

  .admin-listing-delete-all-button {
    min-width: 2.5rem;
    margin-right: 0.5rem !important;
  }

  .admin-listing-filter-menu {
    margin-left: -16.5rem;
  }

  .admin-listing-sort-menu {
    margin-left: -19rem;
  }

  .admin-listing-table-container {
    width: 90%;
    margin-left: 1.5rem;
  }

  .input-container-admin-listing {
    width: max-content;
  }

  .admin-listing-filter-container {
    width: max-content;
  }

  .admin-listing-sort-container {
    width: max-content;
    margin-left: 1rem;
  }

  .admin-listing-delete-modal-inner-container {
    width: 90%;
  }

  .admin-listing-margin-left {
    margin-left: 1rem;
  }

  .admin-listing-margin-right {
    margin-right: 0;
  }

  .admin-listing-icon {
    padding-right: 0;
  }

  .admin-listing-action-menu-container{
    /* margin-left: -30rem; */
  }

  .admin-listing-filter-button-text {
    display: none !important;
  }

  .contactus-outer-container {
    background-color: white;
  }

  .pickup-request-action-menu-container{
    margin-left:-39rem
  }
  .alternate-searchbar{
    display: flex;
    width: 93%;
    margin: 0 0 1rem 1rem;
  }
  .contactus-header-container{
    margin-bottom: 1rem;
  }
}
