 .container_wrapper {
   display: flex;
   flex: 1;
   background-color: var(--l-grey);
   flex-direction: column;
   align-items: flex-start;
   width: calc(100% - 19.375rem);
   max-height: 100vh;
   scrollbar-color: #bdbdbd transparent;
   overflow-y: auto;
 }

 .navbar_container {
   width: 100%;
   background-color: var(--l-grey);
 }

 .body_container {
   width: calc(100% - 4.12rem);
   margin: 0px auto;

 }

 .contact_header_wrapper {
   width: 100%;
   height: 40px;
   margin: 32px 0px;
   display: flex;
   align-items: center;


 }

 .header_title_wrapper {
   width: 25%;
 }

 .header_title_text {
   color: var(--blue);
   font-size: 1.5rem;
   font-style: normal;
   font-weight: 700;
   line-height: 1.5rem;
   font-family: "Barlow Semi Condensed", sans-serif;
   user-select: none;
 }

 .header_form_error_wrapper {
   width: 75%;
 }

 .form_error_container {
   width: 60%;
   /* width: '450px'; */
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 8px;
   background-color: #FEDCE0;

 }

 .form_error_text {
   margin: 10px;
   font-family: "Barlow Semi Condensed", sans-serif;
   font-weight: 400;
   font-size: 16px;
   color: #DC2626;
 }

 .contact_body_wrapper {
   width: 100%;
   margin: 30px 0px;
   display: flex;

 }

 .contact_detail_wrapper {
   width: 45%;
   margin-left: 43px;
 }

 .detail_content_header {
   display: flex;
   flex-direction: row;
   margin-top: 1rem;
   margin-bottom: 1rem;
   align-items: center;
 }

 .detail_header_icon {
   height: 1.5rem;
   width: 1.5rem;
   margin-right: 1rem;
   object-fit: contain;
 }

 .detail_header_text {
   color: #1C3045;
   font-family: "Barlow Semi Condensed", sans-serif;
   font-size: 1rem;
   font-style: normal;
   font-weight: 700;
   line-height: 1.5rem;
 }

 .detail_content_body {
   margin-bottom: 40px;
 }

 .detail_content_descripition {
  font-family: "Barlow Semi Condensed", sans-serif;
   font-size: 16px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
 }

 .detail_text_color {
   margin-top: 12px;
   color: var(--primary-blue, #32569a) !important;
 }

 .connect_icon {
   width: 24px;
   height: 24px;
   margin: 16px 24px 0px 0px;
   cursor: pointer;
 }

 .contact_form_wrapper {
   width: 55%;
   height: 596px;
   margin: 2.06rem 0px 2.06rem 2.06rem;
   display: flex;
   justify-content: center;
   box-shadow: 0px 4px 10px 0px #00000029;
   border-radius: 12px;
   background-color: #FFFFFF;
 }

 .contact_form_container {
   width: 90%;
   margin: 24px 0px;

 }


 .usercontactformInput {
   height: 56px;
   width: 100%;
   border: 1px solid #E0E2E7;
   background-color: #f5f5f5;
   border-radius: 8px;
   margin-bottom: 32px;
   padding: 16px;
   font-size: 14px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
   font-family: "Barlow Semi Condensed", sans-serif;
 }

 .usercontactformInput.right-align::placeholder {
  text-align: right;
  direction: rtl;
}

 .usercontainerformtextarea {
   height: 173px !important;
   resize: none;

 }

 .usercontainerformtextarea:focus-visible {
  outline: none !important;
  
}

.usercontainerformtextarea.right-align::placeholder {
  text-align: right;
  direction: rtl;
}

 .submitBtnConatiner {
   display: flex;
   width: 100%;
   margin: 0px;
   padding: 0px;
   justify-content: flex-end;
 }

 .userContactFormSumbitBtn {
   width: 105px;
   height: 44px;
   padding: 10px, 16px, 10px, 16px;
   border-radius: 8px;
   background: #32569A;
   align-self: flex-end;
   font-family: "Barlow Semi Condensed", sans-serif;
   font-size: 16px;
   font-weight: 700;
   line-height: 24px;
   letter-spacing: 0em;
   color: white;
   margin-bottom: 34px;
   cursor: pointer;
 }

 .userContactFormSumbitBtn:disabled{
  cursor: not-allowed;
 }

 .error {
   border-color: red;
 }

 .success_modal_wrapper {
   width: 100vw;
   height: 100vh;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 10000;
   background: #1C304540;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .success_modal_container {
   width: 640px;
   height: 308px;
   transform: translateY(-20%);
   background: #FFFFFF;
   border-radius: 12px;
   position: relative;
 }

 .success_modal_logo {
   margin-top: 80px;
   margin-left: 266px;
   width: 107px;
   height: 102px;
   display: block;
 }

 .success_modal_text {
   margin-top: 25px;
   margin-left: 188px;
   font-family: "Barlow Semi Condensed", sans-serif;
   font-size: 16px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0em;

 }

 .success_modal_close{
  position: absolute;
  right: 12px;
  top: 12px;
 }

 .success_modal_close img{
  width: 16px;
  height: 16px;
  cursor: pointer;
 }


 .disable-scroll {
   overflow: hidden;
 }

 @media screen and (max-width:414px) {
  .contact_body_wrapper{
    flex-direction: column;
  }
  .contact_form_wrapper{
    margin:2rem 0rem 1rem 0rem;
    width: 100%;
  }

  .contact_detail_wrapper{
    width: 100%;
    margin-left:0px;
  }
 }