.login_block_wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;
}

.mui-select-root{
    padding-right:16px !important;
}

.dropdown_wrapper {
    position: absolute;
    display: flex;
    right: 52%;
    top: 3.5rem;
    z-index: 1000;
    gap: 1rem;
}

.form_block_wrapper,
.image_block_wrapper {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backgorund_image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.enviro_text {
    position: absolute;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    color: rgba(28, 48, 69, 0.80);
}

.logo_wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.logo_wrapper .logo_img {
    width: 16.75rem;
    height: 9.0625rem;
    margin-left: 13%;
}

.form_container {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.welcome_text {
    color: #364F65;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
    letter-spacing: 0.00413rem;
}

.fomr_header_text {
    color: #203B54;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.8125rem;
    letter-spacing: 0.00925rem;
    margin-top: 0.88rem;
}

.error_wrapper {
    margin-top: 0.69rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.5rem;
    background: #FEDCE0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error_text {
    color: #DC2626;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3rem;
    letter-spacing: 0.00413rem;
}

.field_title {
    margin-top: 2.5rem;
    color: #364F65;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4rem;
    letter-spacing: 0.00413rem;
}

.error_bottom_margin {
    margin-top: 1rem !important;
}

.field_input {
    margin-top: 0.75rem;
    width: 100%;
    height: 3.125rem;
    border: 1px solid #E7EDEF;
    border-radius: 10px;
    padding: 1rem 0.87rem;
    /* text css */
    color: #1C3045;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: 0.00356rem;
}

.password_field_input {
    position: relative;
    margin-top: 0.75rem;
    height: 3.125rem;

}

.password_field_input .field_input {
    margin-top: 0px !important;
    height: 100%;
    padding-right: 49px;
}

.password_type_button {
    position: absolute;
    top: 0px;
    right: 7px;
    height: 100%;
    display: flex;
    align-items: center;
}

.field_input:focus,
.field_input:hover {
    background-color: #FFF !important;
    border: 1px solid #BFCC33 !important;
}


.remember_wrapepr {
    width: 100%;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.88rem;
    margin-bottom: 1.87rem;
}

.remember_container {
    height: 100%;
    display: flex;
    align-items: center;

}

.checkobox_container {
    height: 100%;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
}

.remember_text {
    color: #1C3045;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.09375rem;
    letter-spacing: 0.00356rem;
    margin-left: -0.7rem;
}

.forgot_text {
    color: #32569A;
    text-align: right;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
    letter-spacing: 0.00356rem;
}

.terms_condition_text {
    color: #1C3045;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1375rem;
}

.terms_condition_link{
    color: #32569A;
    font-weight: 700;
    /* text-decoration-line: underline; */
}

.login-environment-text{
    margin-top:-3rem;
    margin-right: 1.5rem;
}



/* tablet */


@media(max-width: 927px) {
    .dropdown_wrapper {
        right: 4%;
    }
}

@media (max-width:1279px) {
    .hide-tab-element {
        display: none;
    }

    .login_block_wrapper {
        height: 40.1875rem !important;
    }

    .dropdown_wrapper {
        top: 1.5rem;
    }

    .form_container {
        width: 90%
    }
}


/* mobile */
@media (max-width:767px) {
    .hide-mob-element {
        display: none !important;
    }


    .login_block_wrapper {
        flex-direction: column-reverse;
        height: auto !important;
    }

    .form_block_wrapper {
        width: 100%;
        height: unset;
        padding-bottom: 1rem;
    }

    .image_block_wrapper {
        height: 50vh;
        width: 100%;
    }

    .enviro_text {
        font-size: 1.875rem !important;
    }

    .logo_wrapper {
        justify-content: center;
    }


    .logo_wrapper .logo_img {
        width: 10.1655rem;
        height: 5.5rem;
        margin-top: 1.63rem;
        margin-left: 0px !important;
    }

    .welcome_text {
        font-weight: 500;
        color: #1C3045;
    }

    .fomr_header_text {
        font-size: 1.25rem;
        margin-top: 0.06rem;
    }

    .field_title {
        margin-top: 1.5rem;
    }

    .terms_condition_text{
        font-size: 0.75rem;
    }

}

@media (max-width:430px) {
    .image_block_wrapper {
        height: 40vh !important;
    }
    .login-environment-text{
        margin-top: 0rem;
        margin-right: 0rem;
    }
}

.password-eye-icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
}