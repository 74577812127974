* {
  margin: 0;
  padding: 0;
}

:root {
  --l-grey: rgba(245, 245, 245, 1);
  --blue-70: rgba(28, 48, 69, 0.7);
  --blue: #1c3045;
  --primary-green: #bfcc33;
  --neutral-black-black-500: rgba(29, 31, 44, 1);
  --basic-white: #fff;
  --status-red: rgba(220, 38, 38, 1);
  --green: #288700;
  --secondary-red-red-50: #feecee;
  --primary-blue: #32569a;
}

input,
button,
p textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  outline: none;
  user-select: none;
}

.account-creation-container {
  width: calc(100% - 310px);
  background-color: var(--l-grey);
  overflow-x: hidden;
}

.account-creation-inner-cont{
  background-color: var(--l-grey);
}

.client-details-outer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header-container {
  display: flex;
  width: 100%;
  /* margin-top: 2rem; */
  flex-direction: column;
  padding: 2rem;
  /* margin-bottom: 2rem; */
  /* padding-left: 2.06rem; */
}

.header-title {
  color: var(--blue);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
}

.screen-title-container {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  overflow-x: auto;
}

.screen-title-inner-container{
  display: flex;
  gap: 2rem
}

.client-titles {
  color: var(--blue);
  font-size: 1rem;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
  white-space: nowrap;
}

.client-details-container {
  display: flex;
  flex: 1;
  background-color: var(--l-grey);
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem 2.06rem;
}

.client-details-header {
  margin-top: 2.5rem;
  cursor: default;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.client-details-header-text {
  color: var(--blue);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
}

.current-client-screen {
  border-bottom: 2px solid var(--primary-green);
}

.current-client-screen-title {
  color: var(--primary-blue, #32569a) !important;
  font-weight: 600;
}

.client-titles-container {
  display: flex;
  justify-content: space-around;
  width: 40vw;
  margin: 1.56rem 0 2.06rem -1.06rem;
  background-color: transparent;
}

.client-titles-inner-container {
  background-color: transparent;
  /* margin-right: 2rem; */
  white-space: nowrap;
}

.client-details-form {
  width: 76.9%;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.top-field-container {
  display: flex;
  justify-content: space-between;
  width: 76.9%;
  height: 2rem;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}

.top-field-label {
  height: 100%;
  color: var(--neutral-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
}

.branch-header{
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.sub-header {
  margin-top: 1.3rem;
  margin-bottom: 1.8rem;
}

.top-field-right-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
}

.prompt-container {
  display: flex;
  width: 70.5%;
  height: 2.5rem;
  border-radius: 0.5rem;
  margin-right: 2.56rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.prompt-container-success {
  color: #288700;
  background-color: #dcfbcf;
}
.prompt-container-failure {
  color: var(--status-red);
  background-color: #fedce0;
}

.active-inactive-button-container {
  /* background-color: var(--basic-white); */
  border-radius: 2.5rem;
  height: 2rem;
  min-width: 9rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  overflow: hidden;
}

.button-text {
  text-align: center;
  color: var(--primary-blue, #32569a);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
}

.active-button {
  background-color: transparent;
  flex: 0.45;
}
.inactive-button {
  background-color: var(--basic-white);
  border-radius: 2.5rem;
  flex: 0.55;
}

.selected-text {
  color: var(--basic-white);
}

.selected-button {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
}

.selected-red {
  background-color: rgba(220, 38, 38, 1);
}

.selected-green {
  background-color: var(--green);
}

.address-field {
  height: 5rem !important;
}

.account-creation-input-container {
  width: 100%;
}

.client-endornment-icon {
  background-color: transparent;
  border-width: 0;
  align-self: center;
  height: auto;
  width: auto;
  margin: 0px 1rem;
}

.input-container-client {
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 1px solid #e0e2e7;
  background-color: #f9f9fc;
  width: 100%;
  padding: 0.5rem 0rem;
}

.input-name {
  color: var(--blue-70);
  /* text-align: left; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  transition: 0.3ms;
  user-select: none;

  &:focus {
    border: 1px solid #b8bbc0;
  }
}

.input-field-client {
  letter-spacing: 0.00356rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: var(--blue);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  resize: none;
  outline: none;
  width: 100%;
  border: none;
  user-select: text;
  /* margin-left: 0.875rem; */
  background-color: #f9f9fc;

  &::placeholder {
    color: #727987;
  }
}

.input-field-client:-webkit-autofill,
.input-field-client:-webkit-autofill:hover,
.input-field-client:-webkit-autofill:focus{
  letter-spacing: 0.00356rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: var(--blue);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  resize: none;
  outline: none;
  width: 100%;
  border: none;
  user-select: text;
  margin-left: 0.875rem;
  background-color: #f9f9fc;

  &::placeholder {
    color: #727987;
  }
}

.error-input {
  outline: none;
  border: 1px solid rgba(220, 38, 38, 1) !important;
}

.success-input {
  outline: none;
  border: 1px solid #bfcc33 !important;
}

.input-error-text {
  margin-top: 0.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.00356rem;
  user-select: none;
}

.contact-person-item-container {
  display: flex;
  flex-direction: column;
  width: 76.9%;
}

.contact-person-item-header {
  width: 100% !important;
  align-items: center !important;
}

.icon-button {
  background-color: var(--secondary-red-red-50);
  cursor: pointer;
  align-self: center;
  display: flex;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  transition: background-color 0.3s;
  border-radius: 0.5rem;

  &:hover {
    background-color: #fbbcc3;
  }
}

.icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.person-contact {
  width: 100%;
}

.add-button {
  display: flex;

  width: max-content;
  min-width: 6.5625rem;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border: 1px solid var(--primary-blue);
  background-color: #F6F7FA;
  border-radius: 0.5rem;
  cursor: pointer;
}

.add-button p {
  color: var(--primary-blue);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-fill {
  background-color: var(--primary-blue);
}

.button-fill p {
  color: var(--basic-white);
}

.bottom-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.bottom-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
  gap: 2rem;
  padding:  2rem 2rem 2rem;

}

.bottom-button-container button {
  /* margin: 2rem 2rem 2rem 0; */
}



@media screen and (max-width: 1280px) {
  .account-creation-container{
    width: 100%;
  }
}

@media (max-width: 800px) {
  .top-field-right-container{
    width: auto;
  }
}

@media (max-width: 500px) {
  .screen-title-container {
    width: 100%;
    overflow-x: auto;
  }
}


