.listing_container_wrapper {
  background-color: var(--l-grey);
  width: calc(100% - 19.375rem);
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-color: #bdbdbd transparent;
}

.client_table_wrapper{
  scrollbar-color: #bdbdbd transparent;
}

.client_table_wrapper::-webkit-scrollbar{
  width: "4px",
}

.listing_container_wrapper::-webkit-scrollbar{
  width: "8px",
}

.listing_navbar_container {
  width: 100%;
  background-color: var(--l-grey);
}

.listing_body_container {
  width: calc(100% - 4.12rem);
  margin: 0px auto 22px auto;
}

.client_listing_header_wrapper {
  width: 100%;
  height: 40px;
  margin: 32px 0px 2rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_add_client_wrapper {
  width: 189px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #32569A;
  cursor: pointer;
}

.add_client_icon {
  margin-right: 8px;
}

.add_client_text {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #FFFFFF;
}

.clientlisitning-sub-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.listing-subheader-right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 65%;
}



.delete_btn {
  width: 137px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DC2626;
  color: #FFFFFF;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}

.delete_btn_icon_only {
  width: 1.25rem;
  height: 1.25rem;
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
  display: none;
}

.filter_btn_icon_only,
.sort_btn_icon_only {
  width: 1.25rem;
  height: 1.25rem;
  filter: invert(1);
  display: none;

}



.delete_btn:disabled {
  cursor: not-allowed !important;
}

.delete_btn img {
  margin-right: 8px;
}

.search_container {
  width: 200px;
  height: 40px;
  border: 1px solid #E0E2E7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  margin-right: 16px;
}

.search_container img {
  margin-left: 11px;
  margin-right: 9px;
}

.search_container input {
  width: 152px;
  height: 24px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.search_container ::placeholder {
  color: #858D9D;
  opacity: 1;
  /* Firefox */
}

.client_listing_filter_container {
  width: 5.75rem;
  margin-right: 16px;
  position: relative;
}

.filter_button {
  width: 100%;
  height: 40px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E0E2E7;
  cursor: pointer;
}

.filter_modal_container {
  position: absolute;
  z-index: 100000;
}

.client_listing_sort_container {
  width: 5rem;
  position: relative;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort_button {
  width: 100%;
  height: 40px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E0E2E7;
  cursor: pointer;
}

.sort_modal_container {
  position: absolute;
  top: 56px;
  right: 0px;
  width: 297px;
  height: 190px;
  z-index: 100000;
}


.filter_button img,
.sort_button img {
  margin-left: 14px;
  margin-right: 10px;
}

.filter_button p,
.sort_button p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #667085;
}

/* tabs css override */
.listing_tabs_container {
  width: 100%;
  display: flex;
}

.MuiTabs-flexContainer {
  gap: 32px
}

.listing_tabs_root {
  min-height: unset !important;
  width: 35%;
}

.listing_tabs_scroller {
  height: 40px !important;
}

.listing_tabs_indicator {
  background-color: #BFCC33 !important;
  border-radius: 12px;
}

.client_tabpanel_root {
  margin: 37px 0px 0px 0px;
  width: 100%;
  padding: 0px !important;
}



/* tab css override */
.listing_tab_root {
  padding: 0px !important;
  min-width: unset !important;
  text-align: left;
  /* margin-right: 32px !important; */
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #1C3045 !important;
  font-family: "Barlow Semi Condensed", sans-serif !important;
  text-transform: unset !important;
  opacity: 1 !important;
}

.listing_tab_selected {
  color: #32569A !important;
  font-weight: 600 !important;
}

/* tabpanel css override */
.listing_tabpanel_root {
  margin: 37px 0px 0px 0px;
  width: 100%;
  padding: 0px !important;
}

/* -------- */

.delete_modal_popup_wrapper {
  width: 100vw;
  height: 100vh;
  background: #1C304540;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_modal_popup_container {
  width: 600px;
  height: 244px;
  border-radius: 8px 8px 32px 8px;
  background: #FFFFFF;
  position: relative;

}

.delete_modal_text_message {
  margin: 40px 0px 0px 40px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0F172A;
}
.delete_modal_text_message_arabic {
  margin: 40px 20px 20px 40px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #0F172A;
}

.delete_modal_sub_text_message {
  font-family: "Barlow Semi Condensed", sans-serif;
  margin: 16px 0px 0px 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #0F172A;
}

.delete_modal_sub_text_message_arabic {
  font-family: "Barlow Semi Condensed", sans-serif;
  margin: 40px 20px 10px 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #0F172A;
}
.delete_modal_button_container {
  margin-top: 24px;
  width: 600px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #E2E8F0;
}

.delete_modal_button_container_arabic {
  margin-top: 12px;
  width: 580px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: end;
  border-top: 1px solid #E2E8F0;
}

.delete_modal_cancel_button {
  width: 105px;
  height: 44px;
  padding: 10px, 16px, 10px, 16px;
  border-radius: 8px;
  margin-right: 24px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #32569A;
  color: #32569A;
  cursor: pointer;
  background-color: #FFFFFF;
}

.delete_modal_logout_button {
  width: 105px;
  height: 44px;
  padding: 10px, 16px, 10px, 16px;
  border-radius: 8px;
  border: 1px;
  margin-right: 24px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: #DC2626;
  color: #FFFFFF;
  cursor: pointer;
}

.delete_modal_modal_close {
  position: absolute;
  right: 25px;
  top: 25px;
}

.delete_modal_modal_close_arabic {
  position: absolute;
  left: 25px;
  top: 25px;
}

.delete_modal_modal_close img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.search_container_mob_big {
  display: none !important;
}

.listing_tab_last {
  margin-right: 0px !important;
}

/* pc */
@media (min-width:1280px) {
  .hide-desk-element {
    display: none !important;
  }
}



/* tablate */
@media (max-width:1279px) {
  .hide-tab-element {
    display: none;
  }

  .listing_container_wrapper {
    width: 100%;
    background-color: #FFFFFF;
  }



}

/* tablate and mob */

@media (max-width:921px) {
  .hide-tabsmall-element {
    display: none;
  }

  .client_listing_filter_container,
  .client_listing_sort_container {
    width: 2.75rem !important;
  }

  .delete_btn {
    height: 32px !important;
    width: 2.5rem !important
  }

  .filter_button,
  .sort_button {
    justify-content: center !important;
  }

  .filter_button img,
  .sort_button img,
  .delete_btn img {
    margin: 0px !important;
  }

}

@media (max-width:733px) {
  .hide-mobbig-element {
    display: none !important;
  }

  .search_container_mob_big {
    display: flex !important;
    width: 100% !important;
    margin-top: 16px !important;
    margin-right: 0px !important;

  }

  .search_container_mob_big input {
    width: calc(100% - 50px);
  }

  .listing-subheader-right-container {
    width: 35% !important;
  }

  .listing_tabs_root {
    width: 65% !important;
  }

  .delete_btn {
    margin-right: 0px !important;
  }

  .client_listing_filter_container {
    width: 1.25rem !important;
    margin: 0px 1rem !important;
  }

  .filter_btn_icon_only,
  .sort_btn_icon_only,
  .delete_btn_icon_only {
    display: block !important;
  }

  .filter_modal_container {
    margin-left: -15.3rem !important
  }


}

/* delete modal */
@media (max-width:682px) {
  .delete_modal_popup_container {
    width: 80% !important;
    min-height: 244px !important;
    height: auto;
  }

  .delete_modal_button_container {
    width: 100% !important;
  }
}


/* mobile */
@media (max-width:507px) {

  .hide-mob-element {
    display: none !important;
  }

  .listing_tab_root {
    margin-right: 1.13rem !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;  
  }

}

@media (max-width : 385px) {
  .listing-subheader-right-container {
    margin-top: 10px !important;
    width: 100% !important;
  }

  .listing_tabs_root {
    width: 100% !important;
  }

}