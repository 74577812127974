* {
  margin: 0;
  padding: 0;
}

span,
div {
  margin: 0;
  padding: 0;
}

/*forgot password styling Start */
.MuiOutlinedInput-inputAdornedEnd:hover {
  border-color: 1px solid #bfcc33 !important;
}

.forgotPasswordcontainer {
  padding-top: 2%;
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom-forgot-reset-password-container {
  padding-top: 2%;
  display: flex;
  align-items: flex-start;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  width: min-content;
}

.headerText {
  margin: 2rem 0 0.5rem 0;
  color: #000;
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.sub-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.subHeaderText {
  font-size: 1rem;
  font-style: normal;
  font-weight: 100;
  line-height: 1.5rem;
  text-align: left;
  color: #000;
  font-family: "Barlow Semi Condensed", sans-serif;
  width: 29rem;
}

.subheader-image {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 5px 0 5px;
}

.logoImg {
  height: inherit;
  width: inherit;
  align-self: center;
}

.img-input-icon {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
}

.input-container {
  margin-top: 0.9rem;
  margin-bottom: 0.25rem;
}

.input-field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 28.125rem;
  height: 3.125rem !important;

  border-radius: 0.5rem;
  border: 1px solid var(--neutral-gray-gray-100, #e0e2e7);
  background: var(--neutral-gray-gray-25, #f9f9fc);
  overflow: hidden;
  outline: none;
}

.input-field {
  letter-spacing: 0.00356rem;
  width: 100%;
  border-width: 0;
  height: 100%;
  padding: 0.5rem 0.75rem;
  background: #f9f9fc;
  padding-left: 0.875rem;
  color: #1c3045;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  resize: none;

  &:focus {
    border-width: 0px;
    outline: none;
  }

  &::placeholder {
    color: #727987;
  }
}

.endornment-icon {
  background-color: transparent;
  border-width: 0;
  position: sticky;
  right: 1rem;
  align-self: center;
  height: auto;
  width: auto;
  top: 0.5rem;
}

.inputName {
  color: #364f65;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.00413rem;
  margin-bottom: 0.75rem;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.email-input {
  margin-bottom: 4rem;
}

.confirm-password {
  margin-bottom: 2rem;
}

.inputError {
  border: 1px solid #dc2626;
}

.input-success {
  border: 1px solid #bfcc33;
}

.input-field-margin {
  margin-bottom: 28px;
}

.input-error-text {
  margin-top: 0.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.00356rem;
  /* height: 1rem; */
}

.button {
  /* width: 100%; */
  width: 28.125rem;
  display: flex;
  height: 3rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-width: 0;
  border-radius: 0.5rem;
  background-color: #32569a;
  color: var(--basic-white, #fff);

  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  cursor: pointer;
}

.button-disable {
  /* color: #94a3b8; */
  background-color: #94a3b8;
}

.button-bottom-margin {
  margin-bottom: 2rem;
}

.login-button-text {
  color: #1c3045;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.login-button {
  color: #32569a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.button-disabled {
  color: #94a3b8;
  cursor: default;
}

.error-message {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  border: none;
  width: 100%;
  background-color: rgba(254, 220, 224, 1);
  margin-top: 2rem;
  padding: 0.63rem 0;
  text-align: center;
  border-radius: 0.5rem;
  color: rgba(220, 38, 38, 1);
  font-family: "Barlow Semi Condensed", sans-serif;
}

.margin-below-text-field {
  margin: 2rem 0 0 0;
}

.success-message {
  background-color: rgba(220, 251, 207, 1);
  color: rgba(40, 135, 0, 1);
  margin-top: 2rem;
  padding: 0.63rem 0;
  text-align: center;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
}

@media screen and (max-width: 500px) {
  .inputName,
  .input-field-container {
    width: 20.25rem;
  }
  .button {
    width: 20.25rem;
  }
  .subHeaderText {
    width: 21rem;
  }
}

.below-login-button {
  margin-top: 1rem;
  align-self: center;
}

/* Timer Styling */

.timer-container {
  margin-top: 0.5rem;
}
.timer {
  font-family: BarlowSemiCondensed-Regular, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  color: #1c3045;
}

/* Validation Modal */

.custom-validation-modal-container {
  height: 11.25rem;
  width: 16rem;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  margin-top: 0.75rem;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.modal-item-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  padding-left: 1rem;
}

.item-icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
}

.item-text {
  color: #1c3045;
  text-align: left;
  font-family: BarlowSemiCondensed-Regular, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
}

.custom-reset-successful-container{
  align-items: center;
}

.reset-successful-subheader-container {
  align-items: center;
}

.reset-successful-subheader-text {
  color: #000;
  font-weight: 500;
  line-height: 160%;
  width: auto;
}

/* forgot password styling End*/
