.container_wrapper {
  display: flex;
  flex: 1;
  background-color: var(--l-grey);
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 19.375rem);
  max-height: 100vh;
  scrollbar-color: #bdbdbd transparent;
  overflow-y: auto;
  }

.navbar_container {
  width: 100%;
  background-color: var(--l-grey);
}

.body_container {
  width: calc(100% - 4.12rem);
  margin: 0px auto;

}

.account_header_wrapper {
  width: 100%;
  height: 40px;
  margin: 32px 0px 5px 0px;
  display: flex;
  align-items: center;
}

/* tabs css override */
.account_tabs_container {
  width: 100%;
}
.account_tabs_root{
  min-height: unset !important;
}

.account_tabs_scroller{
  height: 40px !important;
}

.account_tabs_indicator {
  background-color: #BFCC33 !important;
  border-radius: 12px;
}

/* tab css override */
.account_tab_root {
  padding: 0px !important;
  min-width: unset !important;
  text-align: left;
  margin-right: 72px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #1C3045 !important;
  font-family: "Barlow Semi Condensed", sans-serif !important;
  text-transform : unset !important;
  opacity : 1 !important;
}

.account_tab_selected{
  color: #32569A !important;
  font-weight: 600 !important;
}
/* tabpanel css override */
.account_tabpanel_root{
  margin: 37px 0px 0px 0px;
  width: 100%;
  padding:0px !important;
}

/* tabpanl conetent */
.tabpanel_detail_wrapper {
  width: 78%;
}

.tabpanel_content_header {
  margin-top: 1rem;
  align-items: center;
  width: 100%;
}

.tabpanel_header_text {
  color: #1D1F2C;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.tabpanel_header_text img {
  margin-right: 10px;
}

.detail_box{
  width: 100%;
  margin-top: 24px;
}

.tabpanel_sub_titlle {
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  transition: 0.3ms;
  user-select: none;
  color: #1C3045B2;

 
}
.input_container{
  display: flex;
  flex-wrap: wrap;
  font-family: "Barlow Semi Condensed", sans-serif;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #e0e2e7;
  width: calc(100% - 1rem);
  padding: 4px 0.5rem ;
  min-height: 36px;
  color: #1C3045;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background: linear-gradient(0deg, #E2E8F0, #E2E8F0),
  linear-gradient(0deg, #E0E2E7, #E0E2E7);
  position: relative;
  gap: 16px;
}

.report_input_cotainer{
  background: #CBD5E1 !important;
}

.input_container .leftImage{
 margin-right: 7px;
}

.input_container .rightImage, .attechment_container .rightImage{
  position: absolute;
  right: 10px;
 }

.tabpanel_contract_wrapper{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  }

.tabpanel_contract_wrapper .width-50{
  width: 50%;
}



.width-50 .input_container{
  width: 290px !important;
}

.recyle_container{
  display: flex;
  align-items: center;
}
.recyle_container .tabpanel_sub_titlle{
  width: 50%;
  margin-bottom: 0px;

}
.recyle_container .input_container{
  width: 103px;
  justify-content: center;
  font-weight: 500;
}


.input_container .chip_box_container{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 43px;
  padding: 0px 8px;
  height: 32px;
  background: #e1f0e6;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: #1C3045;
}

.attechment_container{
  width: calc(100%);
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 32px 0px #0000000F;
  box-shadow: 0px 4px 8px 0px #00000008;
  background: #F1F5F9;
  margin-top: 0.75rem;
  border-radius: 8px;
  position: relative;

}

.attechment_container .icon_box{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background: #e1f0e6;
}

.attechment_container .detail_box{
  width: calc(100% - 7rem);
  height: 50px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 0px;
}

.detail_box .file_title{
  font-family: 'Inter',sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #0F172A;

}

.view_text{
  font-family: 'Inter',sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #64748B;
  cursor: pointer;
}

.frequency-text{
  color: #8D97A2;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: 400;
  }

@media screen and (max-width:700px) {
  .tabpanel_contract_wrapper{
    flex-direction: column;
  }
}