.main_container_navbar {
    width: calc(100% - 1.5px);
    margin-left: 1.5px;
    background-color: #fff
}

.img_div {
    border-radius: 50px;
    width: 42px;
    height: 42px;
    margin-left: 20px;
    margin-right: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_section {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-left: 3px;
    border: 0px, 0px, 2px, 0px;
    height: 6.875rem;
    align-items: center;
    background-color: #fff;
    width: 50%;
}

.welcomeText {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0px;
    color: #2C3659;
}

.welcomeSubText {
    margin-top: 8px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: '14px';
    font-weight: 500;
    line-height: 22px;
    color: #A7AEC1;
}




/* notification popup */


/* old */

.not-popover {
    border-radius: 0.75rem;
    border: 1px solid var(--neutral-gray-gray-50, #F0F1F3);
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
    width: 26.5rem;
    max-height: 39rem;
    flex-shrink: 0;
    position: absolute;
    top: 80px;
    left: -7rem;
    z-index: 9999;
}

.popover-header {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-bottom: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
    background: var(--neutral-white, #FFF);
    justify-content: space-between;
}

.popover-heading-text {
    color: var(--Blue, #1C3045);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.not-list-container {
    display: flex;
    flex-flow: column;
    max-height: 32rem;
    overflow: visible;
}

.not-row {
    display: flex;
    flex-flow: column;
    padding: 1rem;
}

.not-list-bottom {
    color: var(--Primary-Blue, #32569A);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
    background: var(--neutral-white, #FFF);
}

.row-bottom {
    color: var(--Primary-Blue, #32569A);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
    display: flex;
    justify-content: flex-end;
}

.time-section {
    display: inline-flex;

    .img {
        margin-right: 0.25rem
    }

    .time-text {
        color: var(--neutral-gray-gray-400, #858D9D);
        font-family: "Public Sans", sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.125rem;
        /* 150% */
        letter-spacing: 0.00375rem;
        margin-right: 1rem;
    }
}

.time-dot {
    margin-left: 0.31rem
}

.not-read {
    color: var(--Blue-70, rgba(28, 48, 69, 0.70));
}


/* new ------------------------------------- */

.notification_container {
    position: relative;
}

.notification_modal_container {
    position: absolute;
    z-index: 100000;
    width: 26.75rem;
    border-radius: 0.75rem;
    border: 1px solid var(--neutral-gray-gray-50, #F0F1F3);
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
    max-height: 39rem;
    right: -14.12rem;
    top: 5rem;
}

.menu_papaer_props {
    margin-top: 100px;
}

.navbar-hide-mob-element {
    gap: 1rem;
}

/* tablet */

@media (max-width:1279px) {
    .hide-tab-element {
        display: none;
    }

    .show-tab-profile-element {
        display: flex !important;
    }

    .main_container_navbar {
        padding-top: 98px;
    }

    .tab-logo-container {
        width: 238px;
        height: 98px;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
    }

    .menu-logo {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .img-logo {
        width: 166px;
        height: 90px;
    }

    .tab-welcome-container {
        margin-left: 24px;
        padding-bottom: 15px;
    }

    .tab-userProfile-container {
        margin-right: 1.5rem !important;
    }

    .notification_modal_container {
        right: -0.12rem !important;
        top: 0rem !important;
    }

    .menu_papaer_props {
        margin-top: 61px !important;
        margin-left: 31px !important;
    }

}

.navbar_select_root{
    padding-right: 0 !important;
}


.menuItem_root{
    min-height : auto !important
}

@media (max-width: 583px) {
    .notification_modal_container {
        width: 18.0625rem !important;
        max-height: 44.125rem !important;
    }
}

@media (max-width : 421px) {
    .notification_modal_container {
        right: -4.12rem !important;
    }
}




/* pc */
@media (min-width:1280px) {
    .hide-desk-element {
        display: none;
    }
}

/* mobile */
@media (max-width:414px) {
    .navbar-hide-mob-element {
        display: none !important;
    }

    .main_container_navbar {
        padding-top: 20px !important;
    }

    .tab-logo-container {
        width: 127px !important;
        height: 50px !important;
    }

    .menu-logo {
        width: 24px !important;
        height: 24px !important;
    }

    .img-logo {
        width: 92.22px !important;
        height: 50px !important;
    }

    .main_section {
        height: 50px !important;
    }

    .img_div {
        margin-left: 16px !important;
    }

    .welcomeText {
        font-size: 18px !important;
    }

    .welcomeSubText {
        font-size: 12px !important;
        margin-top: 5px !important;
    }

    /* .notification_modal_container{
       
    } */

}