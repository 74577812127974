.contactus--inner-cont {
  background-color: var(--l-grey);
}
.contactus-header-container {
  display: flex;
  width: 100%;
  padding-top: 2rem;
  flex-direction: column;
  margin-bottom: 2rem;
}

.contactus-right-container{
  width: calc(100% - 310px);
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-color: #bdbdbd transparent;
}
.contactus-right-container::-webkit-scrollbar{
  width: "8px",
}
.contactus-header-top-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.contactus-header-title {
  color: var(--blue);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
}

.contactus-screen-title-container {
  display: flex;
  width: 38%;
  justify-content: space-between;
  background-color: transparent;
}

.contactus-titles {
  font-size: 1rem;
  background-color: transparent;
  font-style: normal;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
  color: var(--primary-blue, #32569a) !important;
  font-weight: 600;
}
.contactus-inner-container {
  width: 78.25%;
}

.contactus-outer-container {
  display: flex;
  flex: 1;
  background-color: var(--l-grey);
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 2.06rem;
  box-sizing: border-box;
}

.client-details-header {
  margin-top: 2.5rem;
  cursor: default;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.client-details-header-text {
  color: var(--blue);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  user-select: none;
}

.contact-titles-container {
  display: flex;
  justify-content: space-around;
  width: 40vw;
  margin: 1.56rem 0 2.06rem -1.06rem;
  background-color: transparent;
}

.contactus-header-inner-container {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-green);
}

.account-creation-input-container {
  width: 100%;
}

.contactus-prompt-container {
  display: flex;
  width: 52.5%;
  height: 2.5rem;
  border-radius: 0.5rem;
  margin-right: 2.56rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.prompt-container-success {
  color: #288700;
  background-color: #dcfbcf;
}
.prompt-container-failure {
  color: var(--status-red);
  background-color: #fedce0;
}

.contactus-chat-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.subheader-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  object-fit: contain;
}

.chat-text {
  color: #000;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.contactus-input-container {
  margin-top: 0.5rem;
}

.contactus-upload-url-container {
  width: 100%;
}

.contactus-upload-header {
  color: var(--Blue-70, rgba(28, 48, 69, 0.7));

  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.check-uncheck-icon {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.375rem;
  margin-right: 1rem;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.check-background {
  background-color: var(--primary-green);
}

.social-img {
  cursor: auto;
  height: 1.5rem;
  width: 2rem;
  margin-right: 1rem;
  object-fit: contain;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
}

.contactus-upload-url-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width:1280px) {
  .contactus-right-container{
    width: 100%;
  }
}