.filter_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 18.5625rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    max-height: 80vh;
    align-items: center;
}

.filter_modal_wrapper_arabic {
    display: flex;
    flex-direction: column;
    width: 18.5625rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    max-height: 80vh;
    align-items: center;
    position: absolute;
    left: 182;
}

.filter_modal_dashboard_wrapper {
    display: flex;
    flex-direction: column;
    width: 18.5625rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    max-height: 65vh;
    align-items: center;
}

.filter_calander_wrapeer {
    width: 364px;
    height: 100%;
}


.client_filter_menu_clear_wrapper {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.client_filter_menu_clear_wrapper_arabic {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: 30px;
    margin-left: 5px;
    padding: 10px;
  }
  
.client_filter_menu_clear_wrapper button {
    width: 44px;
    height: 13px;
    margin-right: 23px;
    color: #8D97A2;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    background-color: unset;
}

.client_filter_accordian_wrapper {
    width: 95%;
    max-height: calc(100% - 115px);
    overflow-y: auto;
    overflow-x: hidden;
}

.client_filter_dashboard_accordian_wrapper {
    width: 95%;
    max-height: calc(100% - 110px);
    overflow-y: auto;
    overflow-x: hidden;
}

.client_accordian_header {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #8D97A2;
}

.filter_accordian_active_header_color {
    color: #0F172A !important;
}

.client_filter_bottom_wraper {
    height: 71px;
}

.accordionDetails_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkbbox_wrapper {
    width: 95%;
    height: 36px;
    display: flex;
    margin-bottom: 8px;
}

.checkbbox_wrapper .checkbox_img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.checkbbox_wrapper .checkbox_title {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
}