.calender-view-reminder-container {
  height: 45.5rem;
}

.calender-view-reminder-item{
  margin-top: 3.2rem !important;
}

@media screen and (max-width: 600px) {
  .calender-view-reminder-item {
    margin-top: 0rem !important;
  }
}
