.profile-page-heading{
  color: var(--Blue, #1C3045);

/* Barlow/16/Semi bold */
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
}

.selected-page
{ 
  color: var(--Primary-Blue, #32569A);
  font-weight: 600;
}

.header-message{
  display: inline-flex;

  color: var(--Green, #288700);
  max-width: 20.06rem;
padding: 0.625rem 1rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
border-radius: 0.5rem;
background: var(--light-green, #DCFBCF);
}

.overlay-text
{
  color: var(--basic-white, #FFF);
text-align: center;

/* Barlow/14/Regular */
font-family: "Barlow Semi Condensed",sans-serif;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 171.429% */
}

.message-text
{ 
  background : transparent;
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
}

.profile-header-container{
  display: flex;
  width : 55%;
  justify-content: space-between;
  margin: 2.5rem 2rem 3.4rem 2rem
}


.profile-heading-title{ 
    color: var(--Blue, #1C3045);

/* Barlow/24/Bold */
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem; /* 100% */
}

.profileadornment{
  position: absolute;
  right: 10px;
}

.detail-box
{
flex-shrink: 0;
border-radius: 0.625rem;
display: flex;
flex-flow: column;
background: var(--white-general, #FFF);
box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
margin: 2rem;
}

.detail-box .box-title{
    width : 100%;
    display: flex;
    flex-wrap : wrap;
    color: var(--black-01, #2C3659);

/* Barlow/16/Semi bold */
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.5rem; /* 150% */
margin-left: 1.12rem;
margin-top : 1.13rem;
}

.opacity
{
  opacity : 1 !important;
}

.bottom-line
{
  width: 4.4375rem;
  opacity: 0;
height: 0.15rem;
flex-shrink: 0;
background: #BFCC33;
margin-top: 0.25rem;
border-radius : 8px;
}

.img-and-email-section{ 
    display: inline-flex;
align-items: center;
gap: 0.875rem;
margin-left : 1.12rem;
margin-top: 1.5rem;
}

.line{ 
height: 0.09375rem;
margin : 1.5rem 1.62rem 2rem 1.12rem;
background: #F5F5F5;
}

.fields-container{ 
    display : flex;
    flex-flow: column;
    gap : 1.5rem;
    margin-bottom : 1.5rem;
    flex-wrap : wrap;
}

.no-gap
{
  gap : 0 !important
}

.profile-row{ 
    margin-left : 1.12rem;
    display : flex;
    flex-flow : row;
    gap : 1.12rem;
    align-items : center;
    margin-right : 1.75rem;
}

.row-align
{
  display : flex;
  align-items : flex-start !important
}

.input-text{ 
    display: flex;
max-width: 31.3125rem;
padding: 0.5rem 0.75rem;
align-items: center;
gap: 0.25rem;
border-radius: 0.5rem;
border: 1px solid #E0E2E7;
position : relative;
background: var(--neutral-gray-gray-25, #F9F9FC);
}

.disable-input{
background: var(--neutrals-cool-gray-200, #E2E8F0);
pointer-events: none;
}

.profile-input{ 
    color: var(--blue-50, var(--Grey, #8D97A2));
    width : -webkit-fill-available;

/* Barlow/14/Regular */
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 171.429% */
padding-right : 18px
}

.icon-img{
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
}

.error-msg{
  color: var(--status-red-600, #DC2626);
font-family: "Barlow Semi Condensed",sans-serif;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 1.225rem */
letter-spacing: 0.00356rem;
}

.profile-bg{
  background: var(--neutral-gray-gray-25, #F9F9FC);
}

.image-container
{ 
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 6.5rem;
    position: relative;
    background-color: rgb(50, 86, 154);
/* background: url(../assets/user-profile.png), lightgray 50% / cover no-repeat, #FFB257; */
   display : flex;
   align-items: center;
   justify-content: center;
}

.email-container
{ 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
}

.user-name{ 
    color: var(--black-01, #2C3659);

/* Barlow/16/Bold */
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem; /* 150% */
}

.user-email{ 
    color: var(--grey-01, #A7AEC1);

/* Barlow/16/Medium */
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 1.5rem; /* 150% */
}

.field-wrapper
{ 
    display : flex;
    flex-flow: column;
    width : 50%;
    gap: 0.75rem ;
}

.label-text{
  color: var(--Blue-70, rgba(28, 48, 69, 0.70));

  /* Barlow/16/Medium */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}

.margin-bottom{
  margin-bottom : -0.75rem
}

.overlay-button{
  position: absolute; /* Position the button absolutely within the container */
  top: 50%; /* Adjust the top position as needed */
  left: 50%; /* Adjust the left position as needed */
  transform: translate(-50%, -50%); /* Center the button horizontally and vertically */
  opacity: 0; /* Initially, hide the button */
  transition: opacity 0.3s;
  width: 6.5rem;
height: 6.5rem;
flex-shrink: 0;
display: flex;
background : #00000099;
border-radius: 6.5rem;
/* background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat, #FFB257; */
flex-flow: column;
color : #ffffff;
align-items : center;
justify-content : center;
gap : 0.25rem;
border : 5px solid #32569A
}

.err{
  border : red 1px solid
}

.image-container:hover .overlay-button {
  opacity: 1; /* Show the button on hover */
}

* {
    margin: 0;
    padding: 0;
  }
  
  
  .pro-account-creation-container {
    width: 100%;
    background-color: var(--l-grey);
    position : relative;
  }
  
  .pro-account-creation-inner-cont{
    background-color: var(--l-grey);
    width : 100%
  }
  
  .client-details-outer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .header-container-pro-page {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* padding-left: 2.06rem; */
  }
  
  .header-title {
    color: var(--blue);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    font-family: ""Barlow Semi Condensed", sans-serif", sans-serif;
    user-select: none;
  }
  
  .profile-screen-title-container {
    display: flex;
    gap: 4.5rem;
    background-color: transparent;
    margin-left : 2rem;
  }
  
  .client-titles {
    color: var(--blue);
    font-size: 1rem;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: ""Barlow Semi Condensed", sans-serif", sans-serif;
    user-select: none;
  }
  
  .current-client-screen {
    border-bottom: 2px solid var(--primary-green);
  }
  
  .current-client-screen-title {
    color: var(--primary-blue, #32569a) !important;
    font-weight: 600;
  }
  
  .client-titles-container {
    display: flex;
    justify-content: space-around;
    width: 40vw;
    margin: 1.56rem 0 2.06rem -1.06rem;
    background-color: transparent;
  }
  
  .client-titles-inner-container {
    background-color: transparent;
  }
  
  .profile-bottom-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
   
    margin-top: 1rem;
  }
  
  .profile-button-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: flex-end;
    width: 100%;
  }

  .save-btn
  {
    min-width: 6.5625rem;
    height: 2.75rem;
  }
  
  .profile-button-container button {
    margin: 2rem 2rem 2rem 0;
  }
  
  @media (max-width: 1279px) {
    .screen-title-container {
      width: 55%;
    }
  }
  
  @media (max-width: 800) {
    .screen-title-container {
      width: 75%;
    }
  }
  
  @media (max-width: 463px) {
    .screen-title-container {
      width: 100%;
    }

    .profile-button-container
    {
      position : relative;
      justify-content: space-evenly;
    }

    .profile-row
    {
      display : flex;
      flex-wrap: wrap;
    }

    .field-wrapper
    {
      width : 100%
    }

    .pro-account-creation-container
    {
      width : auto !important
    }

    .profile-screen-title-container
    {
      gap : 1.5rem
    }


  .profile-button-container button {
    margin: 2rem 2rem 2rem 1rem;
  }

  .image-container
  {
    height : 3.5rem;
    width : 3.5rem;
    border-radius : 3.5rem;
  }

  .overlay-button
  {
    height : 3.5rem;
    width : 3.5rem;
    border-radius : 3.5rem;
  }

  .save-btn
  {
    width: 9rem !important;
    height : 2.5rem !important;
  }

  }


.modal-container {
  height: 11.25rem;
  width: 16rem;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  margin-top: 0.75rem;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top : 32px;
}

.modal-item-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  padding-left: 1rem;
}

.item-icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
}

.item-text {
  color: #1c3045;
  text-align: left;
  font-family: BarlowSemiCondensed-Regular, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
}

  