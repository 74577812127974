.css-1jil7oo-control,.css-uzuyit-control, .css-rjng0p-control,.css-1dlplql-control{ 
  border:none !important;
  box-shadow : none !important;
}

.css-1hb7zxy-IndicatorsContainer, .css-lwy0on6
{ 
  display : none !important
}
.contract-container{ 
    display:flex;
    flex-flow : column;
    margin: var(--dynamic-margin, 0 8.8rem 0 2rem);
    box-sizing: border-box;
}

.ad-align
{
  right : 6px;
  width : 46px;
  background: var(--neutral-gray-gray-25, #F9F9FC);
}

.heading{ 
    color: #000;

    /* Barlow/16/Semi bold */
    font-family: "Barlow Semi Condensed",sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 150% */
    margin-top : 1.5rem
}

.date-container{ 
    display: flex;
    flex-flow: column;
    flex-wrap : wrap;
    flex-shrink: 1;
}

.main-container{ 
    height: max-content;
    width: 100%;
    display: flex;
    flex-flow: column !important;
    background: var(--white-screen-color, #F6F7FA);
}

.control-row{ 
    display: flex;
    flex-flow: row;
    margin-top : 1.5rem;
    justify-content: space-between;
    gap : 4rem
}

.adornment{
  position: absolute;
  pointer-events: none;
  right : 6px;
}

.datepicker-box-input{ 
    display: flex;
    padding: 0.625rem 0.875rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.5rem;
    width : 18rem;
    position: relative;
    /* height: 2.75rem; */
   
    /* width: 18rem; */
  
border: 1px solid var(--Primary-Green, #BFCC33);
background: var(--neutral-gray-gray-25, #F9F9FC);

}

.datepicker-box{ 
    display: flex;
padding: 0.625rem 0.875rem;
align-items: center;
gap: 0.5rem;
align-self: stretch;
border-radius: 0.5rem;
border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
background: var(--neutrals-cool-gray-300, #CBD5E1);
position: relative;
/* height: 2.75rem; */
width : 18rem;
/* height: 2.75rem; */
}

.label-text{ 
    color: var(--Blue-70, rgba(28, 48, 69, 0.70));

/* Barlow/16/Medium */
font-family: "Barlow Semi Condensed",sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 1.5rem; /* 150% */
margin-bottom : 0.75rem;
}

.text-field{ 
    color: var(--Grey, #8D97A2);

/* Barlow/14/Regular */
background: var(--neutrals-cool-gray-300, #CBD5E1);
font-family: "Barlow Semi Condensed",sans-serif;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 171.429% */
width : 100%;
}

.contract-span{ 
  min-width : 76px
}

.text-field-input{ 
    color: var(--Grey, #8D97A2);

    /* Barlow/14/Regular */
    
    font-family: "Barlow Semi Condensed",sans-serif;
    font-size: 0.875rem;
    background: var(--neutral-gray-gray-25, #F9F9FC);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
    width : 100%;
}

.date-text
{
  position : absolute;
  pointer-events: none;
  color: var(--Grey, #8D97A2);

  /* Barlow/14/Regular */
  
  font-family: "Barlow Semi Condensed",sans-serif;
  font-size: 0.875rem;
  background: var(--neutral-gray-gray-25, #F9F9FC);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.bg-color
{
  background: var(--neutrals-cool-gray-300, #CBD5E1) !important;
}

.date-opacity
{
  opacity : 0
}

.right-control-col{ 
    display: flex;
    flex-flow : row;
    gap : 1.5rem;
    align-items: center;
}


.right-control-col-occ{ 
  display: flex;
  flex-flow : row;
  gap : 1.5rem;
  align-items: center;
}

.checkbox{ 
    width: 1.5rem;
height: 1.5rem;
flex-shrink: 0;
}

.control-box{ 
    width : 18rem;
    padding: 0.625rem 0.875rem;
    display : flex;
    flex-flow : row;
    gap: 0.5rem
}

.control-text{ 
    color: #000;

/* Barlow/16/Regular */
font-family: "Barlow Semi Condensed",sans-serif;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
}

.inc-dec-btn{ 

    width: 2rem;
height: 2rem;
flex-shrink: 0;
color : #ffffff;
font-weight: 600;
border-radius: 0.625rem;
background: #32569A;

}

.inner-container{ 
    width: 6.4375rem;
height: 2.5rem;
flex-shrink: 0;
border-radius: 0.625rem;
background: var(--basic-white, #FFF);
gap : 0.75rem;
display : flex;
flex-flow: row;
align-items : center;
}

.pd{
  height : 1.5rem;
}

.container-check {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .container-check .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.375rem;
    display : flex;
border: 1px solid var(--Primary-Green, #BFCC33);
  }
  
  /* On mouse-over, add a grey background color */
  .container-check:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .checkbox-custom
  {
    display: flex;
    height: 26px;
    width : 26px;
    border-radius : 0.375rem;
    background: #BFCC33;
    justify-content: center;
    align-items: center;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-check input:checked ~ .checkmark {
    background-color: #BFCC33;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .container-check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-check input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;  
  border : 1px solid #BFCC33
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
 	top: 6px;
	left: 6px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #BFCC33;
}

.dec-disable
{ 
  background : #8D97A2;
  pointer-events: none;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent; 
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  width: 100% !important;
}

@media (max-width : 820px)
{
    .control-row{
      display : flex !important;
      flex-flow : column;
      flex-wrap : wrap !important;
      gap : 1.5rem ;
    }

    .datepicker-box
    {
      width : 68% !important;
    }

    .datepicker-box-input
    {
      width : 68% !important
    }

    .right-control-col
    {
      flex-wrap : wrap !important;
      gap : 0.5rem;
    }

    .contract-container
    {
      /* margin : 0 1rem 0 2rem */
      margin: var(--dynamic-margin-small, 0 1rem 0 2rem);
    }

    .css-2b097c-container {
      position: relative;
      box-sizing: border-box;
    }
}

@media (max-width : 414px)
{
  .datepicker-box-input
  {
    width : 59% !important;
  }

  .datepicker-box
  {
    width : 59% !important
  }

  .contract-container
  {
    margin : 0 1rem 0 1rem
  }

  .contract-span
  {
    min-width : 0 !important;
  }

  .right-control-col-occ
  {
    display : flex;
    flex-flow: column;
    align-items: flex-start;
    gap : 0.5rem
  }


}
