.waste-top-container {
    width: 97%;
    padding: 2rem;
    box-sizing: border-box;
    margin-bottom: 4rem;
}

.waste-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header-title {
    color: var(--blue);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    font-family: "Barlow Semi Condensed", sans-serif;
    user-select: none;
    white-space: nowrap;
}


.listing_body_container_waste {
    width: calc(100% - -1.82rem);
    height: calc(100vh - 9rem);
    margin: 0px auto;
}

.listing_header_wrapper {
    width: 100%;
    height: 40px;
    margin: 32px 0px 20px 0px !important;
    display: flex;
    align-items: center;
}

.clientlisitning-sub-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
}

.wastbill-listing-subheader-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 75%;
}

.wastebill_listing_tabs_root{
    min-height: unset !important;
    width: 25%;
}

.delete_btn {
    width: 137px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DC2626;
    margin-right: 25px;
}

.delete_btn p{
    color: #FFFFFF;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

.delete_btn img {
    margin-right: 8px;
}

.search_container {
    width: 200px;
    height: 40px;
    border: 1px solid #E0E2E7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    margin-right: 16px;
}

.search_container img {
    margin-left: 11px;
    margin-right: 9px;
}

.search_container input {
    width: 152px;
    height: 24px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.search_container ::placeholder {
    color: #858D9D;
    opacity: 1;
    /* Firefox */
}

.alternate-search-container{
    width: 100%;
    height: 2rem;
    border: 1px solid #E0E2E7;
    border-radius: 8px;
    display: none;
    align-items: center;
    background-color: #FFFFFF;
    margin-top: 16px;
}

.alternate-search-container img {
    margin-left: 11px;
    margin-right: 9px;
}

.alternate-search-container input {
    width: 152px;
    height: 24px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.alternate-search-container ::placeholder {
    color: #858D9D;
    opacity: 1;
    /* Firefox */
}

.wastebill_listing_filter_container {
    width: 5.75rem;
    margin-right: 16px;
    position: relative;
}

 
.client_listing_action_menu_container_waste{
    display: flex;
    width: 12rem;
    height: 7.315rem;
    padding: 0 0.25rem;
    flex-direction: column;
    gap: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
      0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    justify-content: center;
    position: absolute;
    top : 65px;
    right: calc(100% - 3rem);
    z-index: 1;
  }

  .client_listing_action_menu_container_waste_arabic{
    display: flex;
    width: 12rem;
    height: 7.315rem;
    padding: 0 0.25rem;
    flex-direction: column;
    gap: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
      0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    justify-content: center;
    position: absolute;
    top : 65px;
    left: 80;
    z-index: 1;
  }

.wastebill_listing_filter_button {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E0E2E7;
    cursor: pointer;
    margin-right:0.875rem
}

.filter_modal_container {
    position: absolute;
    margin-left: -12.3rem;
    margin-top: 1rem;
    width: 18rem;
    z-index: 100000;
    display: flex;
}

.filter_modal_dashboard_container {
    position: absolute;
    top: 112px;
    right: 28px;
    width: 300px;
    z-index: 100000;
    display: flex;
}

.wastebill_listing_sort_container {
    width: 5rem;
}

.wastebill_listing_sort_button {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E0E2E7;
    cursor: pointer;
    margin-right:2rem;
}

.wastebill_listing_filter_button img,
.wastebill_listing_sort_button img {
    margin-left: 14px;
    margin-right: 10px;
}

.wastebill_listing_filter_button p,
.wastebill_listing_sort_button p {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #667085;
}
.sort_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    height: 100%;
    align-items: center;
}

.sort_modal_wrapper_arabic {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    height: 100%;
    align-items: center;
    position: absolute;
    left: 185;
}


.sort_menu_title_container{
    margin-top: 12px;
}
.sort_menu_container{
    width: 289px;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.sort_menu_title{
    margin-left: 16px;
}
.sort_menu_image_container{
    margin-left: 16px;
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.uncheckRadioImage{
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 0px;
    left: 0px;
}
.checkRadioImage{
    width: 12px;
    height: 12px;
}

.sort_menu_text{
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
}

.sort_menu_text_icon {
    width: 1rem;
    height: 0.5rem;
    flex-shrink: 0;
    margin: 0px 1px;
}

.wastebill-listing-delete-btn{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DC2626;
    margin-right: 25px;
    max-width: 8rem;
}

.wastebill-listing-delete-btn p{
    color: #FFFFFF;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

.wastebill-listing-delete-btn img {
    margin-right: 8px;
}

.wastebill_sort_modal_container{
    position: absolute;
    width: 297px;
    height: 190px;
    z-index: 100000;
    margin-left: -13.3rem;
    margin-top: 0.5rem;
}

@media screen and (max-width:1280px) {
    .waste-top-container{
        background-color: white;
    }
    .wastebill-table-inner-container{
        width: 100%;
    }
    .wastebill-table-wrapper{
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    }
}

@media screen and (max-width:750px) {
   .wastebill-table-header-container{
    width: 45rem;
   }

   .wastebill-table-tr-container{
    width: 45rem;
   }
}

@media screen and (max-width:414px) {
    .waste-top-container{
        padding: 1rem;
    }

    .wastbill-listing-subheader-right-container{
        width: 60%;
    }

    .wastebill_listing_filter_container{
        width: auto;
        margin-right: 0px;
    }

    .wastebill_listing_filter_button{
        border:0px;
    }

    .wastebill_listing_filter_button p{
        display: none;
    }

    .wastebill_listing_filter_button{
        margin-left: 1rem !important;
    }

    .wastebill_listing_filter_button img{
        margin: 0 !important;
    }

    .wastebill_listing_sort_button{
        border:0px;
    }

    .wastebill_listing_sort_button p{
        display: none;
    }
    .wastebill_listing_sort_container{
       width: auto;
    }
    
    .sort_button img{
        margin: 0;
    }

    .search_container{
        width: auto;
        border:0px;
        margin-right: 0px;
    }

    .search_container input{
        display: none;
    }

    .search_container img{
        margin:0
    }

    .wastebill-listing-delete-btn{
        width: auto;
        margin-right: 1rem;
    }

    .wastebill-listing-delete-btn p{
        display: none;
    }

    .wastebill-listing-delete-btn img{
        margin-left: 8px;
    }

    .client_table_wrapper{
        height: auto;
    }
    .wastebill_tabpanel_root{
        margin: 25px 0px 0px 0px;
    }

    .listing_body_container_waste{
        width: calc(100% - -0.5rem);
    }
    .alternate-search-container{
        display: flex;
        margin:0 !important;
        margin-top: 1rem !important;
        width: 100% !important;
    }
}

@media screen and (max-width:733px) {
    .wastebill_listing_filter_container{
        width: auto;
    }
    .wastebill_sort_modal_container{
        margin-left: -15.3rem;
        margin-top: 0.5rem;
    }

    .wastebill_listing_filter_button{
        margin-right: 0px;
    }

    .wastebill_listing_filter_button img,
    .wastebill_listing_sort_button img {
        margin-left: 14px;
        margin-right: 14px;
    }

    .wastebill_listing_filter_button p{
        display: none;
    }

    .wastebill_listing_sort_button{
        margin-right: 0;
    }

    .wastebill_listing_sort_button p{
        display: none;
    }
    .wastebill_listing_sort_container{
       width: auto;
    }
    .wastebill-listing-delete-btn{
        width: auto;
        margin-right: 1rem;
    }

    .wastebill-listing-delete-btn p{
        display: none;
    }

    .wastebill-listing-delete-btn img{
        margin-left: 8px;
    }
}