.client_table_wrapper {
    width: 100%;
    height: calc(100vh - 9rem);
    box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
    background-color: #FFFFFF;
    border-radius: 12px;
}

.client_table_conttainer {
    height: calc(100% - 68px);
    overflow: auto;
}
.client_table_header_container{
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  z-index: 10;
}
.client_table_header_container, .client_table_tr_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3.75rem;
    border-bottom: 2px solid #f6f7fa;
}
/* header */
.client_table_header_field_container{
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
    gap: 1rem;
}
.header_field_emptymessage_container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_field_name_container {
       width: 25%;
}
.header_field_email_container {
    width: 25%;
  }
  .header_field_lastpickup_container{
    width: 20%;
  }
  .header_field_city_container{
    width: 21%;
  }
  .header_field_action_container{
    width: 10%;
    position: relative;
  }
.client_table_name_filed_checkbox_img{
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
}
.client_table_field_text{
    color: var(--Blue, #1c3045);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}
.skelton_text{
  width: 60%;
}

/* body */
.client_table_tr_container{
    height: 5rem;
    
}
.client_table_field_text_name {
       font-weight: 500 !important;
     }
.client_table_field_text_td {
    color: var(--Blue-70, rgba(28, 48, 69, 0.7));
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
  }
  .client_listing_action_img {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }
  
  .client_listing_action_menu_container{
    display: flex;
    width: 12rem;
    height: 9.75rem;
    padding: 0 0.25rem;
    flex-direction: column;
    gap: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
      0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    justify-content: center;
    position: absolute;
    top : 65px;
    right: calc(100% - 3rem);
    z-index: 1;
   
  }

  .client_listing_action_menu_container_arabic{
    display: flex;
    width: 12rem;
    height: 9.75rem;
    padding: 0 0.25rem;
    flex-direction: column;
    gap: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
      0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    justify-content: center;
    position: absolute;
    top : 65px;
    z-index: 1;
    left: 70%;
   
  }
  .client_listing_action_menu_item{
    display: flex;
    height: 2.25rem;
    padding: 0 1.25rem 0 1rem;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 0.5rem;
    background: var(--basic-white, #fff);
    cursor: pointer;
    color: var(--Primary-Blue, #32569a);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  
    &:hover {
      background-color: var(--Light-Green, #e1f0e6);
    }
  }

.client_pagination_container {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.client_pagination_count_text{
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  margin: 0px 24px;
}
.client_pagination_button_container{
  display: flex;
  align-items: center;
  margin: 0px 24px;
  gap: 4px
}
.client_pagination_button{
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  background: #8D97A24D;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Public Sans',sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #32569A;
  cursor: pointer;
}
.client_pagination_active_button{
  background-color: #32569A;
  color: #FFFFFF;
}

.more_button_page{
  cursor: not-allowed;
}
.select_tr{
  background-color: #E1F0E6;
}

.noResultContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.no_result_image{
  width: 394px;
  height: 320px;
}
.no_result_text{
margin-top: 31px;
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
color: #1C3045;
}
.no_result_text_sub{
margin-top: 16px;
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
color: #8D97A2;
}
.clear_filter_btn{
margin-top: 40px;
width: 105px;
height: 44px;
border-radius: 8px;
background-color: #32569A;
font-family: "Barlow Semi Condensed", sans-serif;
font-size: 16px;
font-weight: 700;
line-height: 24px;
color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}


/* mobile */

@media (max-width:689px) {
  .client_table_header_container, .client_table_tr_container {
    width: 1000px !important;
  }
}

