div {
  user-select: none;
}

.dropdown-outer-container {
  position: relative;
  width: 100%;
  margin: 1.5rem 0;
}

.dropdown-input-name {
  color: var(--Blue-70, rgba(28, 48, 69, 0.7));
  margin-bottom: 0.75rem;

  /* Barlow/16/Semi bold */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  user-select: none;
}

.dropdown-input-field {
  display: flex;
  height: 100%;
  padding: 0 0.75rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--neutral-gray-gray-25, #f9f9fc);
  outline: none;
  width: 100%;
  color: var(--Blue, #1c3045);

  /* Barlow/14/Regular */
  font-family: Barlow Semi Condensed, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */

  &::placeholder {
    color: var(--Grey, #8d97a2);
  }
}

.dropdown-placeholder {
  color: var(--Grey, #8d97a2);
  height: 100%;
  display: flex;
  align-items: center;

  /* Barlow/14/Regular */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  padding: 0 0.75rem;
  width: 100%;
}

.dropdown-placeholder-selected {
  color: var(--Blue, #1c3045);
}

.dropdown-endornment-icon {
  background-color: transparent;
  border-width: 0;
  align-self: center;
  height: auto;
  width: auto;
  margin-right: 1rem;
}

.dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #e0e2e7;
  background: var(--neutral-gray-gray-25, #f9f9fc);
  width: 100%;
  height: 2.75rem;
}

.error-input {
  outline: none;
  border: 1px solid rgba(220, 38, 38, 1);
}

.success-input {
  outline: none;
  border: 1px solid #bfcc33 ;
}

.img-dropdown-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.drop-down-container {
  display: flex;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  position: absolute;
  z-index: 10;

  /* shadow-md */
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
}

.drop-down-item {
  display: flex;
  min-height: 36px;
  box-sizing: border-box;
  padding: 10px 20px 10px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
  cursor: pointer;
  font-family: "Barlow Semi Condensed", sans-serif;

  &:hover {
    background-color: var(--Light-Green, #e1f0e6);
  }
}

.disabled-dropdown {
  background-color: #F9F9FC !important;
}
