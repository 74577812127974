.register-admin-top-input-container{
    display: flex; 
    flex-direction: row;
    gap: 50px;
}

@media screen and (max-width:600px) {
    .register-admin-top-input-container{
        flex-direction: column;
        gap: 1.5rem;
    }
}