.dashboard_container_wrapper {
  width: calc(100% - 19.375rem);
  background-color: var(--l-grey);
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-color: #bdbdbd transparent;
}

.dashboard_table_wrapper {
  scrollbar-color: #bdbdbd transparent;
}

.dashboard_table_wrapper::-webkit-scrollbar{
  width: "4px",
}

.dashboard_container_wrapper::-webkit-scrollbar{
  width: "8px",
}

.dashboard_navbar_container {
  width: 100%;
}

.dashboard_body_container {
  width: calc(100% - 4.12rem);
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.viewStatistic {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}

/* renderListStatistic */

.itemStatistic {
  background-image: url('../assets/background_image.png');
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-color: #E1F0E6;
  border-radius: 12px;
  width: calc((100% - 48px) / 3);
  height: 158px;
}

.itemStaticInnerContainer {
  width: 91%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.itemStatistic .item_icon {
  width: 36px;
  height: 36px;

}

.itemdetail_container {
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerTextStatistic {
  color: #1C3045;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Barlow Semi Condensed", sans-serif;
  letter-spacing: 0.08px;
}

.textItem {
  display: flex;
  flex-direction: row;
  font-family: "Barlow Semi Condensed", sans-serif;
  align-items: center;

}

.textNumber {
  color: #1C3045;
  font-size: 28px;
  font-weight: 700;
  font-family: "Barlow Semi Condensed", sans-serif;
  letter-spacing: 0.28px;
}

.textChange {
  color: #1C3045;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.06px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding-left: 12px;
  margin: auto 0;
}


/* chart part */
.chart_part_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 1.5rem;
}

/* Recycling collection */
.recycle_collection_container {
  display: flex;
  width: calc(66% - 10px);
  height: 429px;
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex: 1;
}

.recycle_inner_container {
  width: 96%;
  height: 91%;
}

.recycle_Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  height: 32px;
}

.recycle_chart_canvas_container {
  width: 100%;
  height: calc(100% - 43px);
}

.recycle_chart_canvas_container_inner {
  width: 100%;
  height: 100%;
}

.recycle_chart_canvas_container_inner canvas {
  width: 100% !important;
  height: 100% !important;
}


.view_headerText {
  color: #1C3045;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.24px;
  margin-bottom: 10px;
  width: calc(100% - 140px);
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view_headerTime {
  display: flex;
  flex-direction: row;
  width: 129px;
  height: 34px;
  border: 1px solid #E0E2E7;
  border-radius: 10px;
  justify-content: center;
}

.chosenTimeText {
  color: #1C3045;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.14px;
  text-decoration-line: underline;
}

.unchosenTimeText {
  color: rgba(28, 48, 69, 0.50);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.14px;
}



/* maintainace chart part */
.maintainace_collection_container {
  display: flex;
  flex-direction: column;
  width: calc((100% - 48px) / 3);
  height: 429px;
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
}

.maintaince_inner_container {
  width: 90%;
  height: 91%;
}

.maintainace_headerText {
  color: #1C3045;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.24px;
  margin-bottom: 10px;
}

.viewMoreText {
  color: #32569A;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* Environmental */

.viewEnvironmental {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* margin-top: 18px; */
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
} 

.environmental_inner_container {
  width: 96%;
  margin: 13px 10px;
}
.envir_Header{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
}
.envir_headerText{
    color: #1C3045;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.24px;
    margin-bottom: 10px;
    width: calc(100% - 285px); 
}

.envir_drop_Select_container{
  /* width: 285px; */
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}


.viewDropdown {
  display: flex;
  flex-direction: row;
  width: 129px;
  height: 34px;
  border: 1px solid #E0E2E7;
  border-radius: 10px;
  justify-content: center;
  position: relative;
  inline-size: max-content;
  padding-inline-start: 10px;
}

.viewTitleDropdown {
    width: 100%;  
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  } 

  .textTitleDropdown {
    color: #1C3045;
    font-weight: 600;
    line-height: 18.2px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
  } 
  .iconDropdown {
    margin-left: 10px;
    width: 14px;
    height: 14px;
    object-fit: contain;
    transform: rotate(0deg);
    transition: transform 200ms ease;
  } 

  .rotateIcon{
    transform: rotate(180deg);
    transition: transform 200ms ease;
  }

  .envir_chart_canvas_container {
    width: 100%;
    height: 323px;
    margin-bottom: 20px;
  }
  
  .envir_chart_canvas_container_inner{
    width: 100%;
    height: 100%;
  }

  .envir_chart_canvas_container_inner canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .report_clear_button{
    display: flex;
    height: 2.75rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    font-family: "Barlow Semi Condensed", sans-serif;
    background: var(--Primary-Blue, #32569A); 
    white-space: nowrap;
    margin-bottom: 1rem;
    color: var(--Basic-White, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
  }
 

/* tablet */

@media (max-width : 893px) {
  .chart_part_container {
    flex-direction: column;
  }

  .maintainace_collection_container,
  .recycle_collection_container {
    width: 100%;
  }

  .recycle_collection_container {
    margin-bottom: 1.5rem;
  }
  
}


@media (max-width:1279px) {
  .hide-tab-element {
    display: none;
  }

  .dashboard_container_wrapper{
      width: 100%;
  }

}

/* pc */
@media (min-width:1280px) {
  .hide-desk-element {
    display: none;
  }
}

@media (max-width:1280px) {
.dashboard_container_wrapper{
  background-color: white;
width: 100%;
}
}


/* mobile */

@media (max-width:521px) {
  .envir_Header{
    flex-direction: column;
  }
  .envir_headerText{
    width: 100%;
  }
  .envir_drop_Select_container{
    align-self: flex-end;
  }
}

@media (max-width:430px) {

  /* renderListStatistic */
  .hide-mob-element {
    display: none !important;
  }

  .viewStatistic {
    flex-direction: column;
  }

  .itemStatistic {
    width: 100%;
    height: 72px;
    margin-bottom: 7.62px
  }

  .itemStaticInnerContainer {
    width: 92%;
    height: 93%;
  }

  .itemStatistic .item_icon {
    width: 20px;
    height: 20px;
  }

  .itemdetail_container {
    height: 30px;
  }

  .headerTextStatistic {
    font-size: 12px;
    font-weight: 400;
    line-height: 8px;
    letter-spacing: 0.005em;
    text-align: left;
  }

  .textNumber {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;

  }

  .textChange {
    font-size: 10px;
    font-weight: 500;
    line-height: 6px;
    letter-spacing: 0.005em;
  }

  .view_headerText {
    font-size: 16px;
    font-weight: 700;
  }

  .chosenTimeText {
    font-size: 10px;
  }

  .unchosenTimeText {
    font-size: 10px;
  }

  .maintainace_headerText {
    font-size: 16px;
  }

  .maintaince_inner_container .item_company_title{
    font-size: 14px !important;
  }
  .maintaince_inner_container .item_description{
    font-size: 12px !important;
  }
  .maintaince_inner_container .item_description_date{
    font-size: 12px !important;
  }
  .recycle_chart_canvas_container, .envir_chart_canvas_container{
    overflow-x: scroll;
  }
  .recycle_chart_canvas_container_inner, .envir_chart_canvas_container_inner{
    width: 567px !important

  }

}