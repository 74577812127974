.pickup-listing-alternate-search-bar {
  display: none;
  margin-top: 1rem;
  box-shadow: 10px 10px 300px -31px rgba(0,0,0,1);
}

.pickup-listing-table-container{
    margin-left: 0 !important;
    margin-top: 2rem;
    width:100% !important;
}

.pickup-listing-table-field-branch-container {
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
    width: 12%;
    overflow: hidden;
}
.pickup-listing-table-field-address-container {
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
    width: 12%;
    overflow: hidden;
}
.pickup-listing-table-field-city-container {
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
    width: 11.3%;
    overflow: hidden;
}
.pickup-listing-table-field-pickup-date-container {
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
    width: 11.3%;
    overflow: hidden;
}

.pickup-listing-table-header{ 
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
}

.pickup-status-header{
    width: 16.5%;
}
  
.pickup-listing-table-field-action-container {
    display: flex;
    padding: 1.125rem 1.375rem;
    align-items: center;
    width: 8%;
    /* overflow: hidden; */
    position: relative;
}

.mobile-prompt-container{
    display: none;
}

.pickup-listing-web-container{
    width: 42.2%;
    margin-right: 0;
}

@media screen and (max-width:414px) {
    .pickup-listing-alternate-search-bar {
        display: flex;
    }
    .pickup-listing-table-container{
        margin-top: 1rem;
    }  
    .mobile-prompt-container{
        display: flex;
        width: 97% !important;
    }
    .pickup-listing-web-container{
        display: none !important;
    }
}