.formContainer{
    width: 100%;
    height: 100%;
    display: flex;
}

.filter_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    max-height: 80vh;
    align-items: center;
}

.menu_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    max-height: 80vh;
    align-items: center;
    position: absolute;
    right: 90;
    top: 65;
}


.filter_modal_wrapper_arabic {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: var(--basic-white, #fff);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    max-height: 80vh;
    align-items: center;
    position: absolute;  
    left: 68%; 
    top: 10%;
}



.filter_calander_wrapeer {
    width: 364px;
    height: 100%;
}


.client_filter_menu_clear_wrapper {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
}

.client_filter_menu_clear_wrapper p {
    width: 44px;
    height: 13px;
    margin-right: 23px;
    color: #8D97A2;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    background-color: unset;
    cursor: pointer;
    white-space: nowrap;
}

.client_filter_accordian_wrapper {
    width: 95%;
    max-height: calc(100% - 115px);
    overflow-y: auto;
    overflow-x: hidden;
}

.client_accordian_header {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #8D97A2;
}

.filter_accordian_active_header_color {
    color: #0F172A !important;
}

.client_filter_bottom_wraper {
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
}

.client_filter_bottom_wraper_arabic {
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    margin-left: 20px;
} 
.client_filter_bottom_cancel_btn{
    width: 71px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #32569A;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #32569A;
    cursor: pointer;
    background-color: #FFFFFF;
}

.client_filter_bottom_apply_btn{
    width: 64px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #32569A;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 6px;
    cursor: pointer;
}

.accordionDetails_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkbbox_wrapper {
    width: 95%;
    height: 36px;
    display: flex;
    margin-bottom: 8px;
}


.checkbbox_wrapper .checkbox_img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.checkbbox_wrapper .checkbox_title {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.custom_date_wrapper {
    margin-top: -16px;
    display: flex;
    gap:6px
}

.custom_date_input_wrapper {
    display: flex;
    flex-direction: column;
}

.custom_date_input_title {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color : #94A3B8;
    margin-bottom: 8px;
}

.custom_date_input_container {
    width: 127px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #BFCC33;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}
.custom_date_input_text{
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #1C3045;
}
.input_date_hide{
    top: 0;
    left: 0;
    width: 100%;
    position: absolute !important;
    height: 100%;
    opacity: 0;
    z-index: 1;
    border-radius: 8px;
    cursor: pointer;
}

.custom_date_input_container input {
    cursor: pointer;
  }
  
.custom_date_input_container  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

