.sort_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    height: 100%;
    align-items: center;
}
.sort_modal_wrapper_arabic {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
        0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    height: 100%;
    align-items: center;
    position: absolute;
    left: 74%;
}


.sort_menu_title_container{
    margin-top: 12px;
}
.sort_menu_container{
    width: 289px;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.sort_menu_title{
    margin-left: 16px;
}
.sort_menu_title_arabic{
    margin-right: 16px;
}
.sort_menu_image_container{
    margin-left: 16px;
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.uncheckRadioImage{
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 0px;
    left: 0px;
}
.checkRadioImage{
    width: 12px;
    height: 12px;
}

.sort_menu_text{
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
}

.sort_menu_text_icon {
    width: 1rem;
    height: 0.5rem;
    flex-shrink: 0;
    margin: 0px 1px;
  }