.pickup_container_wrapper {
  display: flex;
  flex: 1;
  background-color: var(--l-grey);
  flex-direction: column;
  align-items: flex-start;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-color: #bdbdbd transparent;
}

.pickup_container_wrapper::-webkit-scrollbar{
  width: "5px",
}

calender-view-reminder-container

.pickup_navbar_container {
  width: 100%;
  background-color: var(--l-grey);
}

.pickup_body_container {
  width: calc(100% - 4.12rem);
  height: 70vh;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.pickup-request-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pickup-request-heading {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--blue);
  margin: 2rem 0;
}

.pickup-request-action-status-alert {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  width: 380px;
  justify-content: center;
  border-radius: 0.5rem;
  background: var(--light-green, #DCFBCF);
  color: var(--Green, #288700);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  position: absolute;
  left: 45%
}

.change-screen-tabs {
  display: flex;
  column-gap: 2rem;
}

.status-tab {
  font-size: 1rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  line-height: 1.5rem;
}

.status-tab-active {
  font-size: 1rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: var(--primary-blue);
  font-weight: 600;
  line-height: 1.5rem;
}

.pickup-request-action-buttons {
  position: absolute;
  display: flex;
  column-gap: 1rem;
  left: 43%;
  transform: translateY(125%);
}

.pickup-request-action-button {
  display: flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 0 1rem;
  cursor: pointer;
}

.pickup-request-button-text {
  font-size: 1rem;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 600;
  line-height: 1.5rem;
}

.decline-all {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.pickup-request-accept-all-button {
  background: #34D399;
}

.pickup-request-decline-all-button {
  background: #DC2626;
}

.pickup-request-action-button-text {
  color: #fff;
}

.pickup-request-filter-button {
  background: var(--neutral-white, #FFF);
  border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
}

.pickup-request-sort-button {
  background: var(--neutral-white, #FFF);
  border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
  margin-left: 16px;
}

.pickup-request-filter-button-text {
  color: var(--neutral-gray-gray-500, #667085);
}

.pickup-request-sort-button-text {
  color: var(--neutral-gray-gray-500, #667085);
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
}

.pickup-request-sort-menu {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
  width: 15.5rem;
  padding: 0.75rem 0.5rem;
  flex-shrink: 0;
  position: absolute;
  right: -27px;
  top: 50px;
  z-index: 5;
}

.pickup-request-sort-menu-header {
  display: flex;
  height: 2rem;
  padding: 0.25rem 1.25rem 0.25rem 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
}

.pickup-request-sort-menu-header-text {
  color: var(--neutrals-cool-gray-900, #0f172a);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.pickup-request-sort-menu-item {
  display: flex;
  height: 2.25rem;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);
  padding: 0.38rem 0;
}

.pickup-request-sort-menu-item-img-container {
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid var(--Primary-Green, #bfcc33);
  border-radius: 2rem;
  stroke-width: 1px;
  stroke: var(--Primary-Green, #bfcc33);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem 0.75rem;
  cursor: pointer;
}

.pickup-request-sort-menu-item-tick-img {
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
}

.pickup-request-sort-menu-item-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.pickup-request-sort-menu-item-text {
  color: var(--neutrals-cool-gray-900, #0f172a);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  user-select: none;
  cursor: auto;
}

.pickup-request-sort-menu-item-arrow {
  width: 1rem;
  height: 0.5rem;
  flex-shrink: 0;
}

.pickup-request-filter-menu-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--basic-white, #FFF);
}

.pickup-request-filter-menu-item-container {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  width: 100%;
  height: 2.25rem;
}

.pickup-request-menu-item-img {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.pickup-request-menu-item-text {
  color: var(--neutrals-cool-gray-900, #0F172A);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.active-header {
  color: #0F172A !important;
}

.pickup-request-filter-menu {
  display: flex;
  flex-direction: column;

  border-radius: 0.5rem;
  border: 1px solid var(--neutrals-cool-gray-200, #e2e8f0);
  background: var(--basic-white, #fff);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
  flex-shrink: 0;
  position: absolute;
  right: -12px;
  top: 50px;
  z-index: 5;

  padding: 0 0.5rem 0.5rem 0.5rem;
}

.filter-seperator {
  margin: 0 0.5rem;
  border-top: 0.5px solid rgba(141, 151, 162, 0.40);
  height: 1px;
  width: 100%;
}

.pickup-request-filter-menu-header {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pickup-request-filter-menu-header p {
  padding-right: 0.5rem;
}

.pickup-request-filter-menu-header-text {
  color: var(--Grey, #8D97A2);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  cursor: pointer;
}

.pickup-request-filter-accordion-wrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-header {
  color: var(--neutrals-cool-gray-900, #0F172A) !important;
}

.accordian-details-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.pickup-request-data-table-wrapper {
  background: var(--neutral-white, #FFF);
  border-radius: 1rem;
  width: 100%;
}

.pickup-request-data-table {
  width: 100%;
  border-collapse: collapse;
  padding: 0 1rem;
}

.pickup-request-data-table-row {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  border-bottom: 1px solid #F0F1F3;
}

.pickup-request-data-table-row-selected {
  background: #E1F0E6 !important;
}

.pickup-request-data-table-cell {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-style: normal;
  padding: 1.125rem 1.375rem;
}

.table-heading {
  text-align: left;
  color: var(--Blue, #1C3045);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.client-name {
  color: var(--Blue, #1C3045);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.data-cell {
  color: var(--Blue-70, rgba(28, 48, 69, 0.70));
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.07px;
  max-height: 5rem;
  overflow: hidden;
  position: relative;
}

.pickup-request-table-footer-container {
  height: 4.25rem;
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pickup-request-list-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

.pickup-request-list-page-inner-container {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: rgba(141, 151, 162, 0.3);
  cursor: pointer;
  margin-left: 0.5rem;
}

.pickup-request-list-page-icon {
  height: 1rem;
  width: 1rem;
}

.pickup-request-list-page-inner-container-selected {
  background: var(--Primary-Blue, #32569a);
}

.pickup-request-list-page-number-text {
  color: var(--Primary-Blue, #32569a);
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.00438rem;
  user-select: none;
}

.pickup-request-list-page-number-text-selected {
  color: var(--neutral-white, #fff);
}

.pickup-request-table-footer-text {
  color: var(--neutral-gray-gray-500, #667085);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.07px;
  margin-left: 1.5rem;
}

.pickup-request-more-action {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.pickup-request-select-icon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.pickup-request-checkbox-img {
  background-color: var(--Primary-Green, #bfcc33);
  border-radius: 0.375rem;
}

.pickup-request-action-menu {
  border-radius: 0.5rem;
  border: 1px solid var(--neutrals-cool-gray-200, #E2E8F0);
  background: var(--basic-white, #FFF);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

  display: flex;
  flex-direction: column;
  width: 12rem;
  padding: 0.25rem;
  z-index: 5;
  position: absolute;
  margin-left: -15rem;
  margin-top: 4rem;
}

.pickup-request-action-menu-item {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
  padding: 0.5rem 1.25rem 0.5rem 1rem;
}

.pickup-request-action-menu-item:hover {
  border-radius: 8px;
  background: var(--Light-Green, #E1F0E6);
}

.accept-action {
  color: var(--status-green-400, #34D399);
}

.decline-action {
  color: var(--status-red-600, #DC2626);
}

.reschedule-action {
  color: var(--Primary-Blue, #32569A);
}

.pickup-request-action-modal {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 40rem;
  padding: 1.5rem;
  background: var(--neutral-white, #FFF);
  border-radius: 0.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pickup-request-action-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pickup-request-action-modal-title-text {
  color: var(--neutral-black-black-500, #1D1F2C);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.16px;
}

.pickup-request-action-modal-close-btn {
  width: 1rem;
  height: 1rem;
}

.pickup-request-action-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.pickup-request-action-modal-content-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-opacity {
  opacity: 0;
  z-index: 2;
  position: absolute;
}

.pickup-request-date-placeholder {
  color: var(--Neutral-Gray-Gray-400, #858D9D);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  z-index: 1;
  position: absolute;
}

.pickup-request-action-modal-content-field-label {
  color: var(--neutral-black-black-300, #777980);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.pickup-request-action-modal-content-field-input-container {
  width: 100%;
  border: 0.5px solid #E0E2E7;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
}

.date-wrapper {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--neutral-gray-gray-25, #f9f9fc);
}

.date-opacity {
  opacity: 0;
}

.date-text {
  color: var(--Neutral-Gray-Gray-400, #858D9D);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0 12px;
}

.pickup-request-action-modal-content-field-input {
  color: var(--Grey, #8D97A2);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
  height: 100%;

  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 0.5rem;
}

.disabled-input {
  background: #CECED0;
}

.pickup-request-reschedule-date-validation {
  color: var(--status-red-600, #DC2626);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
  margin-top: -0.5rem;
}

.pickup-request-action-modal-content-field-comments {
  color: var(--Grey, #8D97A2);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  width: 100%;
  height: 7.5rem;
  border: 0.5px solid #E0E2E7;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 0.5rem;
  resize: none;
}

.pickup-request-action-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pickup-request-save-button {
  background: var(--Primary-Blue, #32569A);
}

.reason-column {
  width: 25%;
}

.pickup-request-view-reason {
  color: #D97706;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.06px;
}

.pickup-request-action-reason-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 28rem;
  padding: 1rem 2.5rem 2.5rem 2.5rem;
  background: var(--neutral-white, #FFF);
  border-radius: 0.5rem 0.5rem 2rem 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pickup-request-action-reason-modal-title {
  display: flex;
  flex-direction: column;
}

.pickup-request-action-reason-modal-close-btn {
  width: 1.5rem;
  height: 1.5rem;
  align-self: flex-end;
  margin-right: -1.5rem;
}

.pickup-request-action-reason-modal-content {
  color: var(--neutrals-cool-gray-900, #0F172A);
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.pickup-request-no-result {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
}

.pickup-request-no-result-img {
  width: 13rem;
  height: 11.5rem;
  flex-shrink: 0;
}

.pickup-request-no-result-message {
  color: #000;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.hide-tabsmall-element{
  font-family: "Barlow Semi Condensed", sans-serif;
  color: var(--Neutral-Gray-Gray-500, #667085);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.00438rem;
}


/* tablet */
@media (max-width:1280px) {
  .pickup-request-action-status-alert {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    justify-content: center;
    display: flex;
    width: 35%;
    border-radius: 0.5rem;
    background: var(--light-green, #DCFBCF);
    color: var(--Green, #288700);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    position: absolute;
    left: 33%;
    transform: translateY(-125%);
  }
}
@media (max-width:1300px) {
  .pickup-request-action-buttons {
    position: initial;
    display: flex;
    column-gap: 1rem;
    transform: none;
  }
}

@media (max-width:920px) {
  .hide-tabsmall-element {
    display: none;
    color: var(--Neutral-Gray-Gray-500, #667085);
  }

  .pickup-request-sort-button {
    background: transparent;
    border: none;
  }

  .pickup-request-filter-button {
    background: transparent;
    border: none;
  }

  .pickup-request-action-modal {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 55%;
    padding: 1.5rem;
    background: var(--neutral-white, #FFF);
    border-radius: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pickup-request-action-reason-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 55%;
    padding: 1rem 2.5rem 2.5rem 2.5rem;
    background: var(--neutral-white, #FFF);
    border-radius: 0.5rem 0.5rem 2rem 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width:507px) {
  .pickup-request-action-modal {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 65%;
    padding: 1.5rem;
    background: var(--neutral-white, #FFF);
    border-radius: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pickup-request-action-reason-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 65%;
    padding: 1rem 2.5rem 2.5rem 2.5rem;
    background: var(--neutral-white, #FFF);
    border-radius: 0.5rem 0.5rem 2rem 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .category-add-button {
    display: none;
  }
}

/* mobile */
@media (max-width:385px) {
  .pickup-request-action-modal {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 75%;
    padding: 1.5rem;
    background: var(--neutral-white, #FFF);
    border-radius: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pickup-request-action-reason-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 75%;
    padding: 1rem 2.5rem 2.5rem 2.5rem;
    background: var(--neutral-white, #FFF);
    border-radius: 0.5rem 0.5rem 2rem 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pickup-request-action-status-alert {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    justify-content: center;
    display: flex;
    width: 60%;
    border-radius: 0.5rem;
    background: var(--light-green, #DCFBCF);
    color: var(--Green, #288700);
    font-family: "Barlow Semi Condensed", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    position: absolute;
    left: 28%;
    transform: translateY(-125%);
  }
}