.addNewWasteBill-right-container {
  width: 100%;
  padding-left: 2rem;
  box-sizing: border-box;
}

.addwastebill-top-container {
  margin-bottom: 0;
  width: 79%;
}

.addwastebill-form-container {
  width: 78.3%;
}

.addpickup-comment-text-field {
  height: 8.125rem;
}

.addNewWasteBill-date-input {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.75rem;
  position: relative;
  border: 1px solid var(--neutral-gray-gray-100, #e0e2e7);
  background: var(--neutral-gray-gray-25, #f9f9fc);
  margin: 0.75rem 0 1.5rem 0;
}

.addwastebill-inputbox {
  background: var(--neutral-gray-gray-25, #f9f9fc);
}

.addwastebill-date-text {
  position: absolute;
  pointer-events: none;
  color: var(--Grey, #8d97a2);
  margin-left: 0.75rem;

  /* Barlow/14/Regular */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.addwastebill-text-color {
  color: var(--Blue, #1c3045);
}

.addwastebill-attachment {
  display: flex;
  height: 15rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px dashed var(--Primary-Blue, #32569a);
  background: var(--basic-white, #fff);
}

.addwastebill-input-name {
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.margin-top-title {
  margin-top: 0.75rem;
}

.addwastebill-attachment-text-container {
  display: flex;
}

.addwastebill-comment-input {
  height: 8.125rem;
}

.addwastebill-file-attachment-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
}

.addwastebill-file-attachment-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  align-items: center;
}

.addwastebill-file-attachment-upper-text {
  color: var(--Blue, #1c3045);
  display: flex;

  /* Barlow/16/Semi bold */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}

.color-primary-blue {
  color: var(--Primary-Blue, #32569a);
}

.margin-left-half {
  margin-left: 0.5rem;
}

.margin-left-quater{
  margin-left: 0.25rem;
}

.addwastebill-bottom-label-text {
  color: var(--Blue, #1c3045) !important;
}

.addwastebill-file-attachment-lower-text {
  color: var(--Blue, #1c3045);
  margin-top: 0.5rem;
  display: flex;

  /* Barlow/14/Regular */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.bottom-fields-container {
  margin-top: 2rem;
}

.addwastebill-top-margin15 {
  margin-top: 1.5rem;
}

.addwastebill-flex-row-space-between {
  display: flex;
  justify-content: space-between;
}

.addwastebill-bottom-input-color {
  color: var(--Blue-70, rgba(28, 48, 69, 0.7));
}

.addwastebill-two-input-row {
  width: 47%;
}

.addwastebill-bottom-buttons {
  margin-right: 2.5rem;
}

.addwastebill-attachments {
  display: flex;
  flex-direction: column;
}

.addwastebill-attachment-outer-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 4.5rem;
  border-radius: 0.5rem;
  background: var(--basic-white, #fff);

  /* shadow-md */
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03);
}

.addwastebill-img-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.addwastebill-img-icon-container {
  display: flex;
  height: 3.5rem;
  width: 3.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: var(--Gradient, #e1f0e6);
  margin: 0 1rem 0 0.5rem;
}

.addwastebill-title-container {
  display: flex;
  flex-direction: column;
}

.addwastebill-attachment-title {
  color: var(--neutrals-cool-gray-900, #0f172a);

  /* text-base/font-normal */
  font-family: "Inter",sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

.addwastebill-attachment-title-footer {
  color: var(--neutrals-cool-gray-500, #64748b);

  /* text-base/font-normal */
  font-family: "Inter",sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

.addwastebill-attachment-inner-container {
  display: flex;
  align-items: center;
}

.addwastebill-img-cross-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.wastebill-success-outer-container {
  height: 100%;
  width: 100%;
  z-index: 10000;
  background: rgba(28, 48, 69, 0.25);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wastebill-success-modal {
  width: 40rem;
  height: 19.25rem;
  border-radius: 0.75rem;
  background: var(--neutral-white, #fff);
  box-sizing: border-box;
  padding: 1.5rem;
}

.addwastebill-cross-modal-img-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.addwastebill-cross-modal-img {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.addwastebill-success-tick-img {
  width: 6.6875rem;
  height: 6.375rem;
  flex-shrink: 0;
}

.addwastebill-success-modal-inner-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
}

.addwastebill-success-text {
  color: #000;
  margin-top: 1.5rem;

  /* Barlow/16/Medium */
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}

.addwastebill-disable-scroll {
  overflow: hidden;
}

.text-right-on-arabic {
  text-align: right;
}

@media screen and (max-width:500px) {

  .addwastebill-flex-row-space-between{
    flex-direction: column;
  }
  .addwastebill-two-input-row{
    width: 100%;
  }
}